<template>
    <div class="step-content">

        <div class="body-1 font-weight-bold mb-5">
            {{ $t('activateAccount.provideKey.titleLbl') }}
        </div>

        <div class="mt-10">
            <v-form @submit.prevent="complete">
                <p>
                  {{ $t('activateAccount.provideKey.enrolmentKeyLbl') }}
                </p>

                <v-text-field
                              :data-cy="'enrollment-key-text-field'"
                              v-model="form.enrollmentKey"
                              :rules="rules.required"
                              :disabled="control.processing"
                              :readonly="isReadOnly"
                              :filled="isReadOnly"
                              outlined class="mt-10">

                    <template v-if="!isReadOnly" v-slot:append>
                        <v-icon tabindex="-1" v-show="!!form.enrollmentKey"
                                @click="form.enrollmentKey = null">mdi-close</v-icon>
                    </template>

                </v-text-field>

                <v-fade-transition :hide-on-leave="true">
                    <div v-show="errors.otherMessages.length"
                         class="error--text font-weight-bold my-1 mx-auto text-center error-msg">

                        <div v-for="error in errors.otherMessages" :key="error">
                            {{ $t(`apiErrors.${error}`) }}
                        </div>
                    </div>
                </v-fade-transition>

            </v-form>
        </div>

        <v-divider class="my-5"></v-divider>

        <v-row>
            <v-col cols="12" sm="6" class="text-left">
                <v-btn @click="$router.push('/')" :disabled="control.processing" primary outlined>
                    <v-icon>mdi-close</v-icon><span class="ml-1">{{ $t('common.cancelBtn') }}</span>
                </v-btn>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
                <v-btn color="primary" @click="back" :disabled="control.processing" outlined class="mr-10">
                    <v-icon>mdi-arrow-left</v-icon><span class="ml-1">{{ $t('common.backBtn') }}</span>
                </v-btn>

                <v-btn :data-cy="'enrollment-key-continue-button'" color="primary" @click="complete" :disabled="control.processing || !form.enrollmentKey">
                    <v-icon>mdi-check</v-icon><span class="ml-1">{{ $t('common.continueBtn') }}</span>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>


<script>

    import ValidationRulesUtils from "@/utilities/validation.rules";
    import BackendService from "@/services/backend.service";
    import ErrorHandler from "@/utilities/error.handler";
    import {OVERLAY_TIME} from "@/utilities/constants";

    export default {
        name: "ActivateAccountEnrollmentKey",
        data: () => ({
            control: {
                processing: false
            },
            form: {
                enrollmentKey: null,
                errorMessage: null
            },
            errors: {
                otherMessages: []
            }
        }),
        computed: {
            rules(){
                return ValidationRulesUtils;
            },

            isReadOnly() {
                return !!this.$route.params.enrollmentKeyParam || (!!this.$route.query.enrollmentKey && this.errors.otherMessages.length === 0);
            }
        },
        methods: {

            init() {
                if (this.$route.params.enrollmentKeyParam && !this.form.enrollmentKey) {
                    this.form.enrollmentKey = this.$route.params.enrollmentKeyParam;
                    return;
                }

                if (this.$route.query.enrollmentKey && !this.form.enrollmentKey) {
                    this.form.enrollmentKey = this.$route.query.enrollmentKey;

                    this.$emit('overlay', true);
                    setTimeout(() => {
                        this.$emit('overlay', false);
                        this.complete();
                    }, OVERLAY_TIME);
                }
            },

            back(){
                this.$emit('back');
            },

            complete(){
                this.control.processing = true;
                this.errors.otherMessages = [];

                BackendService.setEnrollmentKey(this.form.enrollmentKey);

                BackendService.getAccountDetails()
                .then(result => {
                    this.$emit('user-data', result.data);
                    this.$emit('complete');
                })
                .catch(error => {
                    this.errors.otherMessages = ErrorHandler.extractErrorTypes(error);
                })
                .finally(() => {
                    this.control.processing = false;
                });
            }
        }
    }
</script>