<template>
    <div class="fill-height">

        <div class="ml-6">
            {{ $t('activateAccount.titleLbl') }}
        </div>

        <v-stepper v-model="control.steps.current" non-linear class="elevation-0">

            <v-stepper-header class="elevation-0">

                <v-stepper-step step="1" :complete="control.steps.step1.complete"></v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2" :complete="control.steps.step2.complete"></v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3" :complete="control.steps.step3.complete"></v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="4" :complete="control.steps.step4.complete"></v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="5"></v-stepper-step>

            </v-stepper-header>

            <v-stepper-items>

                <v-stepper-content :step="1" class="pt-2 pb-1">
                    <activate-account-country-residence-view ref="ActivateAccountCountryResidence"
                                                             @user-data="control.steps.step1.residence = $event"
                                                             @overlay="control.overlay = $event"
                                                             @complete="completeStep1">
                        <!---->
                    </activate-account-country-residence-view>
                </v-stepper-content>

                <v-stepper-content :step="2" class="pt-2 pb-1">
                    <activate-account-legal-policy-view ref="ActivateAccountLegalPolicy"
                                                        @complete="completeStep2"
                                                        @back="control.steps.current = 1">
                        <!---->
                    </activate-account-legal-policy-view>
                </v-stepper-content>


                <v-stepper-content :step="3" class="pt-2 pb-1">
                    <activate-account-enrollment-key-view ref="ActivateAccountEnrollmentKey"
                                                          @user-data="control.steps.step3.user = $event"
                                                          @overlay="control.overlay = $event"
                                                          @complete="completeStep3"
                                                          @back="control.steps.current = 2">
                        <!---->
                    </activate-account-enrollment-key-view>
                </v-stepper-content>


                <v-stepper-content :step="4" class="pt-2 pb-1">
                    <activate-account-confirm-data-view ref="ActivateAccountConfirmData"
                                                        @user-data="control.steps.step4.userEmail = $event"
                                                        @complete="completeStep4"
                                                        @back="control.steps.current = 3">
                        <!---->
                    </activate-account-confirm-data-view>
                </v-stepper-content>


                <v-stepper-content :step="5" class="pt-2 pb-1">
                    <activate-account-activation-confirmation-view ref="ActivateAccountConfirmation">
                        <!---->
                    </activate-account-activation-confirmation-view>
                </v-stepper-content>

            </v-stepper-items>
        </v-stepper>


        <v-overlay :value="control.overlay" opacity="0.1">
            <v-progress-circular color="#184B67" indeterminate size="64"></v-progress-circular>
        </v-overlay>

    </div>
</template>


<script>

    import ActivateAccountCountryResidenceView from './ActivateAccountCountryResidence';
    import ActivateAccountLegalPolicyView from './ActivateAccountLegalPolicy';
    import ActivateAccountEnrollmentKeyView from './ActivateAccountEnrollmentKey';
    import ActivateAccountConfirmDataView from './ActivateAccountConfirmData';
    import ActivateAccountActivationConfirmationView from './ActivateAccountActivationConfirmation';

    export default {
        name: "ActivateAccount",
        components: {
            ActivateAccountCountryResidenceView,
            ActivateAccountLegalPolicyView,
            ActivateAccountEnrollmentKeyView,
            ActivateAccountConfirmDataView,
            ActivateAccountActivationConfirmationView
        },
        data: () => ({
            control: {
                overlay: false,
                steps: {
                    current: 1,
                    step1: {
                        residence: null,
                        complete: false
                    },
                    step2: {
                        complete: false
                    },
                    step3: {
                        user: null,
                        complete: false
                    },
                    step4: {
                        userEmail: null,
                        complete: false
                    }
                }
            }
        }),
        methods: {
            completeStep1() {
                this.$refs.ActivateAccountLegalPolicy.init(this.control.steps.step1.residence);
                this.control.steps.step1.complete = true;
                this.control.steps.current = 2;
            },

            completeStep2(){
                this.$refs.ActivateAccountEnrollmentKey.init();
                this.control.steps.step2.complete = true;
                this.control.steps.current = 3;
            },

            completeStep3(){
                this.$refs.ActivateAccountConfirmData.init(this.control.steps.step3.user);
                this.control.steps.step3.complete = true;
                this.control.steps.current = 4;
            },

            completeStep4(){
                this.$refs.ActivateAccountConfirmation.init(this.control.steps.step4.userEmail);
                this.control.steps.step4.complete = true;
                this.control.steps.current = 5;
            }
        },
        mounted() {
            this.$refs.ActivateAccountCountryResidence.init();
        }
    }
</script>


<style scoped>

    >>> .step-content {
        max-width: 1000px;
        margin: auto;
    }

    >>> .error-msg {
        max-width: 500px;
    }

</style>