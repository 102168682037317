<template>
    <div class="step-content">

        <p class="mt-5 mb-5">
          {{ $t('activateAccount.residenceCountry.titleLbl') }}
        </p>

        <div>
            <v-form>

                <v-row class="ml-0 mr-0 py-10 my-5">
                    <v-col cols="12" sm="6">
                      <v-row align="center" class="body-1 font-weight-bold fill-height">
                        {{ $t('activateAccount.residenceCountry.selectCountryLbl') }}
                      </v-row>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-select v-model="control.residence.selected"
                                  :items="control.residence.available"
                                  @change="handleResidenceSelected"
                                  item-text="country" item-value="country"
                                  return-object flat>

                            <template v-slot:item="{ item }">
                                <country-flag :country="item.code"/>
                                <span class="ml-3">{{ item.country }}</span>
                            </template>

                            <template v-slot:selection="{ item }">
                                <country-flag :country="item.code"/>
                                <span class="ml-3">{{ item.country }}</span>
                            </template>

                        </v-select>
                    </v-col>

                    <v-spacer></v-spacer>
                </v-row>

            </v-form>
        </div>

        <v-divider class="my-5"></v-divider>

        <v-row>
            <v-col cols="12" sm="6" class="text-left">
                <v-btn color="primary" @click="$router.push('/')" :disabled="control.processing" outlined>
                  <v-icon>mdi-arrow-left</v-icon><span class="ml-1">{{ $t('common.backBtn') }}</span>
                </v-btn>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
                <v-btn @click="complete"
                       :disabled="!control.residence.selected"
                       color="primary"
                       :data-cy="'legal-policy-continue-button'">
                    <v-icon>mdi-check</v-icon><span class="ml-1">{{ $t('common.continueBtn') }}</span>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>


<script>

    import {LEGAL_POLICY_FILES_MAPPING, OVERLAY_TIME} from '@/utilities/constants';
    import CountryFlag from 'vue-country-flag';

    export default {
        name: "ActivateAccountCountryResidence",
        components: {
            CountryFlag
        },
        data: () => ({
            control: {
                residence: {
                    selected: null,
                    available: []
                }
            }
        }),
        methods: {

            init() {
                if (this.$route.query.countryCode) {
                    this.control.residence.selected = LEGAL_POLICY_FILES_MAPPING.find(ob => ob.code === this.$route.query.countryCode) || null;


                    if (this.control.residence.selected) {
                        this.handleResidenceSelected(this.control.residence.selected);

                        this.$emit('overlay', true);
                        setTimeout(() => {
                            this.$emit('overlay', false);
                            this.$emit('complete');
                        }, OVERLAY_TIME);
                    }
                }
            },

            handleResidenceSelected(residence){
                this.$emit('user-data', residence);
            },

            complete(){
                this.$emit('complete');
            }
        },
        mounted() {
            this.control.residence.available = LEGAL_POLICY_FILES_MAPPING;
        }
    }
</script>