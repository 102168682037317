import axios from 'axios';
import {LEGAL_POLICY_FILES} from '@/utilities/constants';

axios.defaults.headers.common = {
    "Content-Type": "application/json"
};

const DOWNLOAD_BASE_PATH = '/aea-web/download';
const API_BASE_PATH = '/aea-web/api';

let ENROLLMENT_KEY = null;

export default {

    setEnrollmentKey(enrollmentKey) {
        ENROLLMENT_KEY = enrollmentKey;
    },


    DOWNLOAD_HREF(documentKey) {
        return `${DOWNLOAD_BASE_PATH}/policy/${LEGAL_POLICY_FILES[documentKey]}`;
    },

    DOWNLOAD_NAME(documentKey) {
        return LEGAL_POLICY_FILES[documentKey];
    },


    getAccountDetails() {
        return axios.get(API_BASE_PATH + '/account/details', {
            headers: {
                'Enrollment-Key': ENROLLMENT_KEY
            }
        });
    },

    activateAccount(email, password, subscription, language) {
        return axios.post(API_BASE_PATH + '/account/activate',
            {
                email, password, subscription, language
            },
            {
                headers: {
                    'Enrollment-Key': ENROLLMENT_KEY
                }
            }
        );
    },

    recoveryProvideEmail(email, language, token) {
        return axios.post(API_BASE_PATH + '/account-recovery/email',
            {
                email, language
            },
            {
                headers: {
                    token: token
                }
            }
        );
    },

    recoveryValidateToken(token, validationCode) {
        return axios.post(API_BASE_PATH + '/account-recovery/details', {
                validationCode
            },
            {
                headers: {
                    token: token
                }
            }
        );
    },

    recoveryProvidePassword(token, validationCode, password) {
        return axios.post(API_BASE_PATH + '/account-recovery/password', {
                newPassword: password,
                validationCode
            },
            {
                headers: {
                    token: token
                }
            }
        );
    }
}
