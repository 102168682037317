import Vue from 'vue';
import Router from 'vue-router';
import HomeView from '../views/Home';
import StartView from '../views/Start';
import ActivateAccountView from '../views/activate-account/ActivateAccount';
import ChangeEmailView from '../views/change-email/ChangeEmail';
import RecoverCredentialsProcessView from "@/views/recover-credentials/RecoverCredentialsProcessView.vue";

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/',
            component: HomeView,
            children: [
                {
                    path: '',
                    component: StartView
                },
                {
                    path: '/activate-account/:enrollmentKeyParam?',
                    component: ActivateAccountView
                },
                {
                    path: '/change-email',
                    component: ChangeEmailView
                },
                {
                    path: '/recover-credentials',
                    component: RecoverCredentialsProcessView
                }
            ]
        }
    ]
});

router.beforeEach((to, from, next) => {
    next();
});

export default router;
