<template>
    <v-app>

        <v-fade-transition :hide-on-leave="true">
            <router-view/>
        </v-fade-transition>

    </v-app>
</template>


<script>
    export default {
        beforeCreate() {
            window.onerror = function(error){
                console.log(JSON.stringify(error));
            }
        }
    };
</script>


<style>

    .v-application {
        font-family: 'Lato', sans-serif !important;
    }

    .v-application .title {
        font-family: 'Lato', sans-serif !important;
    }

    .v-app-bar {
        background: white !important;
    }

    .fill-height {
        height: 100%;
    }

    .centered-container {
        height: 100% !important;
        max-width: 800px !important;
        margin: auto !important;
    }

</style>
