
export default {
    translations: {
        mainPage: {
            languageLbl: 'Seleccioná el idioma',
            titleLbl: '¡Bienvenido a Bunge MyAccess!',
            subtitleLbl: 'Que te gustaría hacer?',
            activateAccountLbl: 'Activar la cuenta',
            accountRecoveryLbl: 'Olvidé la contraseña...',
            changeEmailLbl: 'Cambiar e-mail'
        },
        activateAccount: {
            titleLbl: 'Activación de cuenta',
            residenceCountry: {
                titleLbl: 'Para empezar necesitamos un poco de información sobre vos. ¡Sólo unos pocos clics más!',
                selectCountryLbl: '¿Cuál es tu país de residencia?'
            },
            legalPolicy: {
                titleLbl: 'Acuerdo de política legal',
                confirmLbl: 'Confirmo que leí, comprendí y acepto las condiciones anteriores'
            },
            provideKey: {
                titleLbl: 'Proporcionar clave de acceso',
                enrolmentKeyLbl: 'Utilizá la clave de acceso que te proporcionó el área de Recursos Humanos. No compartas la clave con nadie.'
            },
            confirmData: {
                titleLbl: '¡Solo un par detalles más y terminamos!',
                disclaimerLbl: 'Bunge utilizará tu dirección de correo electrónico personal con el único propósito de acceder al autoservicio a las aplicaciones de Bunge y restablecer la contraseña. Bunge nunca utilizará esta información para ningún otro tipo de comunicación.',
                confirmLbl: 'Confirmo que los datos de la cuenta anterior son correctos',
                subscriptionLbl: 'Me gustaría acceder a la aplicación Bunge GoConnect para recibir noticias, boletines, comunicaciones sobre Bunge y mi entorno de trabajo.',
                form: {
                    firstName: 'Nombre',
                    lastName: 'Apellido',
                    hireDate: 'Fecha de contratación',
                    personExternalId: 'ID de empleado de Bunge',
                    emailAddress: 'Dirección de correo electrónico',
                    password: 'Contraseña',
                    passwordConfirm: 'Confirmación de contraseña',
                    emailConfirm: 'Confirmar correo electrónico',
                },
                confirmation: {
                    title: 'Activación de cuenta',
                    text: 'Tu cuenta ahora está activada. Esperá por favor.'
                },
                check: {
                    passw: 'Las contraseñas no coinciden',
                    email: 'Los correos no concuerdan'
                },
                dialog: {
                    title: 'Confirmar la activación de la cuenta',
                    subtitle: 'Tu cuenta está activada.',
                    firstName: 'Nombre',
                    lastName: 'Apellido',
                    hireDate: 'Fecha de contratación',
                    personExternalId: 'ID de empleado de Bunge',
                    email: 'Correo electrónico',
                    question: '¿Está seguro que quiere proceder?'
                }
            },
            securityQuestion: {
                titleLbl: 'Establecer pregunta y respuesta de seguridad',
            },
            activationConfirm: {
                activatedLbl: '¡Cuenta activada!',
                titleLbl: 'Genial, ¡ya está todo!',
                confirmEmailLbl: 'Le enviamos un e-mail de confirmación con toda la información que necesita a su dirección de correo electrónico {value}.',
                linkLbl: 'Encontrará enlaces a las aplicaciones de Bunge.',
                thankYouLbl: 'Gracias!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: 'Introducí la dirección de correo electrónico que utilizaste para registrarte',
            },
            validateCode: {
                titleLbl: 'Verifique si hay un código de validación único enviado a su dirección de correo electrónico. Nota amable: este código es válido solo por 5 minutos',
                contentLbl: 'Por favor ingrese el mismo código de validación a continuación'
            },
            validView: {
                titleLbl: 'Que te gustaría hacer?',
                loginBtn: 'Recuperar inicio de sesión',
                passwordBtn: 'Restablecer la contraseña'
            },
            loginView: {
                contentLbl: 'Tu nombre de usuario es:'
            },
            passwordView: {
                headerLbl: 'Por favor ingrese la nueva contraseña',
                sendRequestBtn: 'Confirmar la nueva contraseña',
                successPasswordLbl: 'La contraseña de la cuenta se cambió correctamente.',
                redirectLbl: 'En unos segundos será redirigido a la página externa.',
                redirectBtn: 'Redirigir'
            }
        },
        common: {
            continueBtn: 'Continuar',
            backBtn: 'Atrás',
            cancelBtn: 'Cancelar',
            downloadBtn: 'Descargar',
            returnBtn: 'Regresar a la pagina principal',
            confirmBtn: 'Confirmar',
            closeBtn: 'Cerrar'
        },
        validation: {
            required: 'Este campo es obligatorio',
            email: 'Proporcioná una dirección de correo electrónico válida',
            password: 'Proporcioná una contraseña válida (1 carácter especial: !@#$%^&*+=, 1 letra mayúscula, 1 dígito, de 8 a 25 caracteres)'
        },
        apiErrors: {
            UNKNOWN_ERROR: 'Ha ocurrido un error desconocido',
            INVALID_ENROLLMENT_KEY_ERROR: 'Se proporcionó una clave de acceso no válida',
            ACCOUNT_NOT_FOUND_ERROR: 'Cuenta no encontrada',
            ACCOUNT_INACTIVE_ERROR: 'La cuenta está inactiva',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: 'Cuenta ya activada',
            INVALID_EMAIL_FORMAT_ERROR: 'Proporcioná una dirección de correo electrónico válida',
            INVALID_EMAIL_ERROR: 'Proporcioná una dirección de correo electrónico diferente',
            INVALID_PASSWORD_FORMAT_ERROR: 'Proporcioná una contraseña válida (1 carácter especial: !@#$%^&*+=, 1 letra mayúscula, 1 dígito, de 8 a 25 caracteres)',
            INVALID_VALIDATION_CODE: '¡Ups! Este es un código no válido. Inténtalo de nuevo',
            VALIDATION_CODE_EXPIRED: '¡Lo siento! El código de validación ha caducado. Por favor regenera el código',
            INVALID_RECOVERY_TOKEN: '¡Ups! Su sesión \'Olvidé mi contraseña\' ha caducado. Por favor comience de nuevo',
            MAX_ATTEMPTS: '¡Oh, no! Superaste el número de intentos. Por favor comience de nuevo'
        },
        versioning: {
            title: 'Registro de cambios de versión',
            version: 'Versión',
            description: 'Descripción de cambios'
        },
        idle: {
            title: 'Sesión expirada',
            info1: 'La sesión expiró debido a un largo período de inactividad.',
            info2: 'La aplicación se recargará después de {value} segundos',
            reload: 'Recargar ahora'
        }
    }
}
