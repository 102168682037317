<template>
    <v-row>
        <v-col cols="12" class="text-center primary--text mb-10">
            <div class="title">
                {{ $t('recoveryCredentials.loginView.contentLbl') }}
            </div>

            <div class="title mt-5">
                {{ userDetails.username }}
            </div>

            <div class="mt-10">
                <v-btn @click="onBack" outlined class="py-5" :data-cy="'navigation-menu-item-recover-credentials'">
                    <v-icon color="primary" size="25">mdi-arrow-left-bold-outline</v-icon>
                    <span class="ml-3 primary--text">{{ $t('common.backBtn') }}</span>
                </v-btn>
            </div>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "LoginPreview",
        props: {
            userDetails: Object
        },
        methods: {
            onBack() {
                this.$emit('change', {key: 'back'})
            }
        }
        ,
        mounted() {
            this.$emit('mounted', this);
        }
    }
</script>
