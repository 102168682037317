<template>
    <v-row align="center" :align-content="$vuetify.breakpoint.xsOnly ? 'start' : 'center'" class="fill-height centered-container">
        <v-col>
            <component :is="currentView"
                       :token="security.token"
                       :validation-code="security.validationCode"
                       :user-details="userDetails"
                       @change="onChildChange"
                       @mounted="onChildMounted"/>
        </v-col>
    </v-row>
</template>

<script>
    import ProvideEmail from "@/views/recover-credentials/children/ProvideEmail.vue";
    import ValidateCode from "@/views/recover-credentials/children/ValidateCode.vue";
    import LoginPasswordOption from "@/views/recover-credentials/children/LoginPasswordOption.vue";
    import LoginPreview from "@/views/recover-credentials/children/LoginPreview.vue";
    import PasswordChange from "@/views/recover-credentials/children/PasswordChange.vue";

    export default {
        name: "RecoverCredentialsProcessView",
        components: {
            ProvideEmail,
            ValidateCode,
            LoginPasswordOption,
            LoginPreview,
            PasswordChange
        },
        data: () => ({
            control: {
                component: null
            },
            currentView: ProvideEmail,
            security: {
                token: null,
                validationCode: null
            },
            userDetails: null,
            state: {
                ProvideEmail: {
                    next: ValidateCode
                },
                ValidateCode: {
                    next: LoginPasswordOption
                },
                LoginPasswordOption: {
                    nextLogin: LoginPreview,
                    nextPassword: PasswordChange
                },
                LoginPreview: {
                    back: LoginPasswordOption
                },
                PasswordChange: {
                    back: LoginPasswordOption
                }
            }
        }),
        methods: {
            onChildChange(event) {
                if (event.token) {
                    this.security.token = event.token;
                }

                if (event.validationCode) {
                    this.security.validationCode = event.validationCode;
                }

                if (event.userDetails) {
                    this.userDetails = event.userDetails;
                }

                if (event.key) {
                    this.currentView = this.state[this.control.component.$options.name][event.key];
                }
            },

            onChildMounted(component) {
                this.control.component = component;
            },
        },
        mounted() {
            this.security.token = null;
            this.security.validationCode = null;
        }
    }
</script>
