import { render, staticRenderFns } from "./ChangeEmail.vue?vue&type=template&id=59671d38&scoped=true&"
import script from "./ChangeEmail.vue?vue&type=script&lang=js&"
export * from "./ChangeEmail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59671d38",
  null
  
)

export default component.exports