<template>
    <v-snackbar v-model="control.visible"
                :color="options.color || 'info'"
                :timeout="options.timeout || 3000"
                top right>

        {{ options.text }}

        <template v-slot:action="{ attrs }">
            <v-btn @click="control.visible = false"
                   v-bind="attrs"
                   dark text>
                {{ $t('common.closeBtn') }}
            </v-btn>
        </template>

    </v-snackbar>
</template>


<script>
    export default {
        data: () => ({
            control: {
                visible: false
            },
            options: {}
        }),
        methods: {
            show(options){
                this.options = options || {};
                this.control.visible = true;
            }
        }
    }
</script>


<style scoped>

</style>