
export default {
    translations: {
        mainPage: {
            languageLbl: 'Selecione o idioma',
            titleLbl: 'Bem-vindo ao Bunge MyAccess!',
            subtitleLbl: 'O que você gostaria de fazer?',
            activateAccountLbl: 'Ativar conta',
            accountRecoveryLbl: 'Esqueci minha senha...',
            changeEmailLbl: 'Mude o e-mail'
        },
        activateAccount: {
            titleLbl: 'Ativação de conta',
            residenceCountry: {
                titleLbl: 'Vamos obter um pouco de informação sobre você para começar. Só mais alguns cliques!',
                selectCountryLbl: 'Qual é seu país de residência?'
            },
            legalPolicy: {
                titleLbl: 'Acordo de política legal',
                confirmLbl: 'Confirmo ter lido, compreendido e concordado com as condições acima'
            },
            provideKey: {
                titleLbl: 'Forneça a chave de inscrição',
                enrolmentKeyLbl: 'Use a chave de inscrição única fornecida a você por seu HRBP. Por favor, não compartilhe a chave com ninguém.'
            },
            confirmData: {
                titleLbl: 'Confirme os dados da conta',
                disclaimerLbl: 'Seu endereço de e-mail pessoal será usado pela Bunge com o único propósito de autoatendimento de acesso aos aplicativos da Bunge e redefinição de senha. A Bunge nunca utilizará essas informações em nenhum outro tipo de comunicação.',
                confirmLbl: 'Eu confirmo que os dados da conta acima estão corretos',
                subscriptionLbl: 'Desejo acessar o aplicativo Bunge GoConnect para receber notícias, newsletters, comunicados sobre a Bunge e meu ambiente de trabalho',
                form: {
                    firstName: 'Primeiro nome',
                    lastName: 'Sobrenome',
                    hireDate: 'Data de admissão',
                    personExternalId: "ID de funcionário da Bunge",
                    emailAddress: 'Endereço de e-mail',
                    password: 'Senha',
                    passwordConfirm: 'Confirmar a senha',
                    emailConfirm: 'Confirmar e-mail',
                },
                confirmation: {
                    title: 'Ativação de conta',
                    text: 'Sua conta agora está ativa. Por favor aguarde.'
                },
                check: {
                    passw: 'Senhas não coincidem',
                    email: 'Emails não coincidem'
                },
                dialog: {
                    title: 'Confirme a ativação da conta',
                    subtitle: 'Sua conta agora será ativada.',
                    firstName: 'Primeiro nome',
                    lastName: 'Sobrenome',
                    hireDate: 'Data de admissão',
                    personExternalId: "ID de funcionário da Bunge",
                    email: 'E-mail',
                    question: 'Tem certeza de que deseja continuar?'
                }
            },
            securityQuestion: {
                titleLbl: 'Definir pergunta e resposta de segurança',
            },
            activationConfirm: {
                activatedLbl: 'Conta ativada!',
                titleLbl: 'Ótimo, está tudo pronto!',
                confirmEmailLbl: 'Enviamos um email de confirmação para o seu endereço de email',
                linkLbl: 'Você encontrará links para aplicativos da Bunge.',
                thankYouLbl: 'Obrigado!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: 'Por favor, digite o endereço de e-mail que você usou durante o cadastro.',
            },
            validateCode: {
                titleLbl: 'Verifique se há um código de validação único enviado para seu endereço de e-mail. Observação: este código é válido apenas por 5 minutos',
                contentLbl: 'Por favor insira o mesmo código de validação abaixo'
            },
            validView: {
                titleLbl: 'O que você gostaria de fazer?',
                loginBtn: 'Recuperar login',
                passwordBtn: 'Redefinir senha'
            },
            loginView: {
                contentLbl: 'Seu login é:'
            },
            passwordView: {
                headerLbl: 'Forneça a nova senha',
                sendRequestBtn: 'Confirmar nova senha',
                successPasswordLbl: 'A senha da conta foi alterada com sucesso.',
                redirectLbl: 'Em alguns segundos você será redirecionado para a página externa.',
                redirectBtn: 'Redirecionar'
            }
        },
        common: {
            continueBtn: 'Prosseguir',
            backBtn: 'Voltar',
            cancelBtn: 'Cancelar',
            downloadBtn: 'Download',
            returnBtn: 'Voltar à página principal',
            confirmBtn: 'Confirme',
            closeBtn: 'Fechar'
        },
        validation: {
            required: 'Este campo é obrigatório',
            email: 'Por favor, forneça um endereço de e-mail válido',
            password: 'Por favor, forneça uma senha válida (1 caractere especial: !@#$%^&*+=, 1 letra maiúscula, 1 dígito, 8 a 25 caracteres)'
        },
        apiErrors: {
            UNKNOWN_ERROR: 'Ocorreu um erro desconhecido',
            INVALID_ENROLLMENT_KEY_ERROR: 'Chave de inscrição fornecida inválida',
            ACCOUNT_NOT_FOUND_ERROR: 'Conta não encontrada',
            ACCOUNT_INACTIVE_ERROR: 'Conta está inativa',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: 'Conta já ativadad',
            INVALID_EMAIL_FORMAT_ERROR: 'Formato de e-mail inválido',
            INVALID_EMAIL_ERROR: 'Forneça um endereço de e-mail diferente',
            INVALID_PASSWORD_FORMAT_ERROR: 'Formato de senha inválido (1 caractere especial: !@#$%^&*+=, 1 letra maiúscula, 1 dígito, 8 a 25 caracteres)',
            INVALID_VALIDATION_CODE: 'Ops! Este é um código inválido. Por favor, tente novamente',
            VALIDATION_CODE_EXPIRED: 'Desculpe! O código de validação expirou. Por favor, gere novamente o código',
            INVALID_RECOVERY_TOKEN: 'Ops! Sua sessão \'Esqueci minha senha\' expirou. Por favor, comece de novo',
            MAX_ATTEMPTS: 'Oh não! Você excedeu o número de tentativas. Por favor, comece de novo'
        },
        versioning: {
            title: 'Registro de mudança de versão',
            version: 'Versão',
            description: 'Descrição das mudanças',
        },
        idle: {
            title: 'Sessão expirada',
            info1: 'A sessão expirou devido a um longo período de inatividade.',
            info2: 'O aplicativo será recarregado após {value} segundos',
            reload: 'Recarregue agora'
        }
    }
}
