
export default {
    translations: {
        mainPage: {
            languageLbl: 'Selecteaza limba',
            titleLbl: 'Bine ați venit la Bunge MyAccess!',
            subtitleLbl: 'Ce ai vrea sa faci?',
            activateAccountLbl: 'Activează contul',
            accountRecoveryLbl: 'Am uitat parola...',
            changeEmailLbl: 'Schimbă e-mail'
        },
        activateAccount: {
            titleLbl: 'Activare cont',
            residenceCountry: {
                titleLbl: 'Haideți să obținem câteva informații despre dumneavoastră pentru a începe. Doar câteva clicuri!',
                selectCountryLbl: 'Care este țara dumneavoastră de reședință?'
            },
            legalPolicy: {
                titleLbl: 'Acord de politică juridică',
                confirmLbl: 'Confirm că am citit, am înțeles și sunt de acord cu condițiile de mai sus'
            },
            provideKey: {
                titleLbl: 'Furnizați cheia de înscriere',
                enrolmentKeyLbl: 'Folosiți cheia unică de înscriere care v-a fost furnizată de Resursele umane. Vă rugăm să nu împărtășiți cheia cu nimeni altcineva.'
            },
            confirmData: {
                titleLbl: 'Confirmați datele contului',
                disclaimerLbl: 'Adresa dvs. de e-mail personală va fi utilizată de Bunge în scopul unic al accesului în autoservire la aplicațiile Bunge și resetarea parolei. Bunge nu va folosi niciodată aceste informații niciun alt tip de comunicare.',
                confirmLbl: 'Confirm că datele contului de mai sus sunt corecte',
                subscriptionLbl: 'Aș dori să accesez aplicația Bunge GoConnect pentru a primi știri, buletine informative, comunicări despre Bunge și mediul meu de lucru',
                form: {
                    firstName: 'Prenume',
                    lastName: 'Nume',
                    hireDate: 'Data angajării',
                    personExternalId: "ID-ul angajatului Bunge",
                    emailAddress: 'Adresa de e-mail',
                    password: 'Parola',
                    passwordConfirm: 'Confirmati parola',
                    emailConfirm: 'Confirmați adresa de e-mail',
                },
                confirmation: {
                    title: 'Activare cont',
                    text: 'Contul dvs. este acum activat. Va rog, asteptati.'
                },
                check: {
                    passw: 'parolele nu se potrivesc',
                    email: 'Email-urile nu se potrivesc'
                },
                dialog: {
                    title: 'Confirmați activarea contului',
                    subtitle: 'Contul dvs. va fi activat acum.',
                    firstName: 'Prenume',
                    lastName: 'Nume',
                    hireDate: 'Data angajării',
                    personExternalId: "ID-ul angajatului Bunge",
                    email: 'E-mail',
                    question: 'Sigur doriți să continuați?'
                }
            },
            securityQuestion: {
                titleLbl: 'Intrebari de securitate',
            },
            activationConfirm: {
                activatedLbl: 'Cont activat!',
                titleLbl: 'Minunat, sunteți gata!',
                confirmEmailLbl: 'Am trimis un e-mail de confirmare la adresa ta de e-mail',
                linkLbl: 'Veți găsi link-uri către aplicațiile Bunge.',
                thankYouLbl: 'Vă mulțumim!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: 'Vă rugăm să introduceți adresa de e-mail pe care ați folosit-o în timpul înregistrării',
            },
            validateCode: {
                titleLbl: 'Vă rugăm să verificați dacă este trimis un cod de validare unic la adresa dvs. de e-mail. Vă rugăm să rețineți: acest cod este valabil doar 5 minute',
                contentLbl: 'Vă rugăm să introduceți același cod de validare mai jos'
            },
            validView: {
                titleLbl: 'Ce ai vrea sa faci?',
                loginBtn: 'Preluați autentificare',
                passwordBtn: 'Reseteaza parola'
            },
            loginView: {
                contentLbl: 'Autentificarea dvs. este:'
            },
            passwordView: {
                headerLbl: 'Vă rugăm să furnizați noua parolă',
                sendRequestBtn: 'Confirmați noua parolă',
                successPasswordLbl: 'Parola contului a fost schimbată cu succes.',
                redirectLbl: 'În câteva secunde vei fi redirecționat către pagina externă.',
                redirectBtn: 'Redirecţiona'
            }
        },
        common: {
            continueBtn: 'Continua',
            backBtn: 'Înapoi',
            cancelBtn: 'Anulare',
            downloadBtn: 'Descarca',
            returnBtn: 'Reveniți la pagina principală',
            confirmBtn: 'A confirma',
            closeBtn: 'Închide'
        },
        validation: {
            required: 'Acest câmp este obligatoriu',
            email: 'Vă rugăm să furnizați o adresă de e-mail validă',
            password: 'Vă rugăm să furnizați o parolă validă (1 caracter special: !@#$%^&*+=, 1 literă mare, 1 cifră, 8 până la 25 de caractere)'
        },
        apiErrors: {
            UNKNOWN_ERROR: 'A apărut o eroare necunoscută',
            INVALID_ENROLLMENT_KEY_ERROR: 'A fost furnizată o cheie de înscriere nevalidă',
            ACCOUNT_NOT_FOUND_ERROR: 'Contul nu a fost găsit',
            ACCOUNT_INACTIVE_ERROR: 'Contul este inactiv',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: 'Contul este deja activat',
            INVALID_EMAIL_FORMAT_ERROR: 'Format de email invalid',
            INVALID_EMAIL_ERROR: 'Vă rugăm să furnizați o adresă de e-mail diferită',
            INVALID_PASSWORD_FORMAT_ERROR: 'Format de parolă nevalid (1 caracter special: !@#$%^&*+=, 1 literă mare, 1 cifră, 8 până la 25 de caractere)',
            INVALID_VALIDATION_CODE: 'Hopa! Acesta este un cod nevalid. Vă rugăm să încercați din nou',
            VALIDATION_CODE_EXPIRED: 'Îmi pare rău! Codul de validare a expirat. Vă rugăm să regenerați codul',
            INVALID_RECOVERY_TOKEN: 'Hopa! Sesiunea dvs. „Am uitat parola” a expirat. Vă rugăm să începeți din nou',
            MAX_ATTEMPTS: 'Oh nu! Ați depășit numărul de încercări. Vă rugăm să începeți din nou'
        },
        versioning: {
            title: 'Jurnalul de modificare a versiunii',
            version: 'Versiune',
            description: 'Descrierea modificărilor',
        },
        idle: {
            title: 'Sesiunea a expirat',
            info1: 'Sesiunea a expirat din cauza perioadei lungi de inactivitate.',
            info2: 'Aplicația se va reîncărca după {value} secunde',
            reload: 'Reîncarcă acum'
        }
    }
}
