<template>
    <v-row align="center" :align-content="$vuetify.breakpoint.xsOnly ? 'start' : 'center'" class="fill-height centered-container">

        <v-col cols="12" class="text-center">
            <img src="@/assets/bunge-logo-large.jpg" class="logo"/>
        </v-col>


        <v-col cols="12" class="pb-10 text-center">
            <div class="primary--text" style="font-size: 3rem">{{ $t('mainPage.titleLbl') }}</div>
<!--            <div class="secondary&#45;&#45;text mt-5" style="font-size: 2rem">{{ $t('mainPage.subtitleLbl') }}</div>-->
        </v-col>

        <v-col cols="12">

            <v-btn @click="$router.push('/activate-account')" outlined block class="py-10"
                   :data-cy="'navigation-menu-item-activate-account'">
                <v-icon color="primary" size="45">mdi-account-check-outline</v-icon>
                <span class="ml-3 primary--text">{{ $t('mainPage.activateAccountLbl') }}</span>
            </v-btn>

            <v-btn @click="$router.push('/recover-credentials')" outlined block class="py-10 mt-5"
                   :data-cy="'navigation-menu-item-recover-credentials'">
              <v-icon color="primary" size="45">mdi-account-lock-outline</v-icon>
              <span class="ml-3 primary--text">{{ $t('mainPage.accountRecoveryLbl') }}</span>
            </v-btn>

        </v-col>

<!--        <v-col cols="12" sm="6">-->

<!--            <v-btn class="py-10" outlined block @click="$router.push('/change-email')">-->
<!--                <v-icon color="primary" size="43">mdi-email-edit-outline</v-icon>-->
<!--                <span class="ml-3 primary&#45;&#45;text">{{ $t('mainPage.changeEmailLbl') }}</span>-->
<!--            </v-btn>-->

<!--        </v-col>-->

    </v-row>
</template>


<script>
    export default {
        name: "Start"
    }
</script>


<style scoped>

    .logo {
        width: 100%;
        max-width: 500px;
    }

    .credentials-button {
        position: fixed;
        bottom: 50px;
        left: 20px;
    }

</style>
