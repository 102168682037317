import { render, staticRenderFns } from "./LegalDocumentContent_rczNH6.vue?vue&type=template&id=58da7600&scoped=true&"
var script = {}
import style0 from "./LegalDocumentContent_rczNH6.vue?vue&type=style&index=0&id=58da7600&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58da7600",
  null
  
)

export default component.exports