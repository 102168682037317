<template>
    <v-main>

        <v-app-bar app flat>
            <v-spacer></v-spacer>

            <v-select v-model="locale.selected" :items="locale.available"
                      item-value="code" item-text="label"
                      @change="changeLocale"
                      no-data-text=""
                      solo flat hide-details
                      style="max-width: 250px;">

                <template v-slot:selection class="text-right">
                    {{ $t('mainPage.languageLbl') }}
                </template>

            </v-select>

        </v-app-bar>


        <div class="fill-height pa-5">
            <v-fade-transition :hide-on-leave="true">
                <router-view></router-view>
            </v-fade-transition>
        </div>


        <v-footer app dark color="primary">
            <v-spacer></v-spacer>
            <div @click="logVersion">© 2022 Bunge Limited</div>
        </v-footer>


        <idle-dialog-component>
            <!---->
        </idle-dialog-component>


        <toast-component ref="toast">
            <!---->
        </toast-component>

    </v-main>
</template>


<script>

import versions from '../../../all-employees-access-web/src/main/resources/app.version.json';
import IdleDialogComponent from '../components/utilities/idle.dialog.component'
import ToastComponent from '../components/utilities/toast.component';
import Versioning from '../utilities/versioning';
import EventBus from '../utilities/event.bus';

export default {
    components: {
        ToastComponent,
        IdleDialogComponent
    },
    data: () => ({
        control: {
            processing: false,
            versioning: {
                version: null,
                dialogShowing: false
            }
        },
        locale: {
            selected: null,
            available: []
        }
    }),
    methods: {

        changeLocale(locale){
            this.$vuetify.lang.current = locale;
            this.$i18n.locale = locale;
        },

        showToast(options){
            if(this.$refs.toast){
                this.$refs.toast.show(options);
            }
        },

        logVersion(){
            console.log(JSON.stringify(versions));
        }
    },
    created(){

        EventBus.$on('show-success-toast', $event => {
            this.showToast(
                Object.assign({color: 'success'}, $event)
            );
        });

        EventBus.$on('show-error-toast', $event => {
            this.showToast(
                Object.assign({color: 'error'}, $event)
            );
        });

        EventBus.$on('show-info-toast', $event => {
            this.showToast(
                Object.assign({color: 'info'}, $event)
            );
        });
    },
    mounted(){
        this.locale.available = this.$i18n.localeConfigs;
        this.locale.selected = this.$i18n.locale;

        let languageDetected = window.navigator.userLanguage || window.navigator.language;
        for (let lang of this.locale.available) {
            if (languageDetected.toLowerCase().includes(lang.code)) {
                this.$nextTick(() => {
                    this.locale.selected = lang.code;
                    this.changeLocale(lang.code);
                });
                return;
            }
        }

        this.control.versioning.version = Versioning.getAppVersion();
    }
};
</script>


<style scoped>

>>> .theme--light.v-app-bar.v-toolbar.v-sheet {
    background: none;
    border-bottom: 1px solid #e0e0e0;
}

>>> .theme--light.v-footer {
    background: none;
}

>>> .version-marker {
    position: fixed;
    bottom: 5px;
    right: 10px;
}

>>> .clickable:hover {
    cursor: pointer;
}

</style>
