
export default {
    translations: {
        mainPage: {
            languageLbl: 'Nyelv kiválasztása',
            titleLbl: 'Üdvözöljük a Bunge MyAccess-nél!',
            subtitleLbl: 'Mit szeretne csinálni?',
            activateAccountLbl: 'Fiók aktiválása',
            accountRecoveryLbl: 'Elfelejtett jelszó...',
            changeEmailLbl: 'E-mail cím módosítása'
        },
        activateAccount: {
            titleLbl: 'Fiók aktiválása',
            residenceCountry: {
                titleLbl: 'Legyen egy kis információ rólad, hogy elkezdhessük. Már csak néhány kattintás!',
                selectCountryLbl: 'Mi a lakóhelye szerinti ország?'
            },
            legalPolicy: {
                titleLbl: 'Jogszabályi megállapodás',
                confirmLbl: 'Megerősítem, hogy elolvastam, megértettem és elfogadom a fenti feltételeket.'
            },
            provideKey: {
                titleLbl: 'Regisztrációs kulcs megadása',
                enrolmentKeyLbl: 'Használja a humánerőforrás által biztosított egyedi regisztrációs kulcsot. Kérjük, ne ossza meg a kulcsot senki mással.'
            },
            confirmData: {
                titleLbl: 'Még néhány részlet, és máris kész!',
                disclaimerLbl: 'Az Ön személyes e-mail címét a Bunge kizárólag a Bunge alkalmazásokhoz való önkiszolgáló hozzáférés és a jelszó visszaállítása céljából használja. A Bunge soha nem használja fel ezt az információt semmilyen más típusú kommunikációra.',
                confirmLbl: 'Megerősítem, hogy a fenti számlaadatok helyesek',
                subscriptionLbl: 'Szeretnék hozzáférni a Bunge GoConnect alkalmazáshoz, hogy híreket, hírleveleket, közleményeket kapjak a Bunge-ról és a munkakörnyezetemről.',
                form: {
                    firstName: 'Keresztnév',
                    lastName: 'Vezetéknév',
                    hireDate: 'Munkaviszony kezdete',
                    personExternalId: 'Bunge munkavállalói azonosító',
                    emailAddress: 'E-mail cím',
                    password: 'Jelszó',
                    passwordConfirm: 'Jelszó megerősítése',
                    emailConfirm: 'E-mail megerősítése',
                },
                confirmation: {
                    title: 'Fiók aktiválása',
                    text: 'A fiókját aktiváltuk. Kérjük, várjon.'
                },
                check: {
                    passw: 'A jelszavak nem egyeznek',
                    email: 'Az e-mailek nem egyeznek'
                },
                dialog: {
                    title: 'Fiók aktiválásának megerősítése',
                    subtitle: 'A fiókja mostantól aktív.',
                    firstName: 'Keresztnév',
                    lastName: 'Vezetéknév',
                    hireDate: 'Munkaviszony kezdete',
                    personExternalId: 'Bunge munkavállalói azonosító',
                    email: 'E-mail',
                    question: 'Biztos, hogy folytatni szeretné?'
                }
            },
            securityQuestion: {
                titleLbl: 'Biztonsági kérdés és válasz beállítása'
            },
            activationConfirm: {
                activatedLbl: 'Fiók aktiválva!',
                titleLbl: 'Nagyszerű, minden készen áll!',
                confirmEmailLbl: 'A {value} e-mail címre küldtünk egy visszaigazoló e-mailt az összes szükséges információval.',
                linkLbl: 'Itt talál linkeket a Bunge alkalmazásokhoz.',
                thankYouLbl: 'Köszönöm!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: 'Kérjük, adja meg a regisztráció során használt e-mail címét.',
            },
            validateCode: {
                titleLbl: 'Kérjük, ellenőrizze az e-mail címére küldött egyszeri érvényesítő kódot. Figyelem: Ez a kód csak 5 percig érvényes',
                contentLbl: 'Kérjük, adja meg alább ugyanazt az érvényesítő kódot'
            },
            validView: {
                titleLbl: 'Mit szeretnél csinálni?',
                loginBtn: 'Bejelentkezés lekérése',
                passwordBtn: 'Jelszó visszaállítása'
            },
            loginView: {
                contentLbl: 'Az Ön bejelentkezési neve:'
            },
            passwordView: {
                headerLbl: 'Kérjük, adja meg az új jelszót',
                sendRequestBtn: 'Új jelszó megerősítése',
                successPasswordLbl: 'A fiók jelszava sikeresen megváltozott.',
                redirectLbl: 'Néhány másodperc múlva a rendszer átirányítja a külső oldalra.',
                redirectBtn: 'Átirányítás'
            }
        },
        common: {
            continueBtn: 'Folytatás',
            backBtn: 'Vissza',
            cancelBtn: 'Mégsem',
            downloadBtn: 'Letöltés',
            returnBtn: 'Vissza a főoldalra',
            confirmBtn: 'Megerősítés',
            closeBtn: 'Bezárás'
        },
        validation: {
            required: 'Ez a mező kötelező',
            email: 'Kérjük, adjon meg érvényes e-mail címet',
            password: 'Kérjük, adjon meg érvényes jelszót (1 speciális karakter: !@#$%%^&*+=, 1 nagybetű, 1 számjegy, 8-25 karakter)'
        },
        apiErrors: {
            UNKNOWN_ERROR: 'Ismeretlen hiba történt',
            INVALID_ENROLLMENT_KEY_ERROR: 'Érvénytelen regisztrációs kulcsot adott meg',
            ACCOUNT_NOT_FOUND_ERROR: 'Fiók nem található',
            ACCOUNT_INACTIVE_ERROR: 'A fiók inaktív',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: 'A fiók már aktiválva van',
            INVALID_EMAIL_FORMAT_ERROR: 'Érvénytelen e-mail formátum',
            INVALID_EMAIL_ERROR: 'Kérjük, adjon meg másik e-mail címet',
            INVALID_PASSWORD_FORMAT_ERROR: 'Érvénytelen jelszóformátum (1 speciális karakter: !@#$%^&*+=, 1 nagybetű, 1 számjegy, 8-25 karakter)',
            INVALID_VALIDATION_CODE: 'Hoppá! Ez érvénytelen kód. Kérlek próbáld újra',
            VALIDATION_CODE_EXPIRED: 'Sajnálom! Az érvényesítési kód lejárt. Kérjük, generálja újra a kódot',
            INVALID_RECOVERY_TOKEN: 'Hoppá! Az „Elfelejtett jelszó” munkamenete lejárt. Kérlek kezdd újra',
            MAX_ATTEMPTS: 'Óh ne! Túllépte a próbálkozások számát. Kérlek kezdd újra'
        },
        versioning: {
            title: 'Verzióváltoztatási napló',
            version: 'Verzió',
            description: 'Változások leírása'
        },
        idle: {
            title: 'Munkamenet lejárt',
            info1: 'A munkamenet lejárt a hosszú ideig tartó inaktivitás miatt.',
            info2: 'Az alkalmazás {value} másodperc után újratöltődik.',
            reload: 'Töltse be újra'
        }
    }
}
