<template>
  <v-dialog v-if="options.details" v-model="control.visible" max-width="800" :persistent="persistent">

    <v-card>

      <v-card-title>
        <div class="headline">
          <v-icon color="primary" class="header-icon">{{ options.icon || '' }}</v-icon>
          <span class="primary--text">{{ $t('activateAccount.confirmData.dialog.title') }}</span>
        </div>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="mt-10 pl-7 subtitle-1">
        <p>{{ $t('activateAccount.confirmData.dialog.subtitle') }}</p>
        <br/>

        <v-row>
          <v-col cols="12" md="5">
            <p v-if="options.details.firstName">{{ $t('activateAccount.confirmData.dialog.firstName') }}: <b>{{options.details.firstName}}</b></p>
          </v-col>
          <v-col cols="12" md="7">
            <p v-if="options.details.lastName">{{ $t('activateAccount.confirmData.dialog.lastName') }}: <b>{{options.details.lastName}}</b></p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="5">
            <p v-if="options.details.hireDate">{{ $t('activateAccount.confirmData.dialog.hireDate') }}: <b>{{options.details.hireDate}}</b></p>
          </v-col>
          <v-col cols="12" md="7">
            <p v-if="options.details.personExternalId">{{ $t('activateAccount.confirmData.dialog.personExternalId') }}: <b>{{options.details.personExternalId}}</b></p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="7">
            <p>{{ $t('activateAccount.confirmData.dialog.email') }}: <b>{{options.details.email}}</b></p>
          </v-col>
        </v-row>

        <br/>
        <p>{{ $t('activateAccount.confirmData.dialog.question') }}</p>
      </v-card-text>

      <slot>
        <!-- optional contents -->
      </slot>

      <v-card-actions class="pr-6 pb-4">
        <v-spacer></v-spacer>

        <v-btn @click="cancel"
               color="primary"
               text>
            {{ $t('common.cancelBtn') }}
        </v-btn>

        <v-btn
               :data-cy="'activation-dialog-confirm-button'"
               @click="confirm"
               :disabled="control.locked"
               color="warning"
               class="ml-10">
            {{ $t('common.confirmBtn') }}
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
export default {
  props: {
    persistent: Boolean
  },
  data: () => ({
    control: {
      visible: false,
      locked: false
    },
    options: {},
    callback: null
  }),
  methods: {
    show(options, callback, onshow) {
      this.options = options || {};
      this.callback = callback;
      this.control.visible = true;

      if (onshow) {
        onshow(this);
      }
    },

    setLocked(isLocked) {
      this.control.locked = isLocked;
    },

    confirm() {
      this.control.visible = false;
      if (this.callback) {
        this.callback();
      }
    },

    cancel() {
      this.control.visible = false;
      this.$emit('cancel');
    }
  }
}
</script>

<style scoped>
  .subtitle-1 >>> p {
    margin-bottom: 0;
  }

  .subtitle-1 >>> .col-12 {
    padding-top: 0;
    padding-bottom: 0;
  }
</style>
