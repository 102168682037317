<template>
    <div class="fill-height">

        <div class="ml-6 mt-3">Change email address</div>

        <v-stepper v-model="control.steps.current" class="elevation-0 mt-2">

            <v-stepper-header class="elevation-0">

                <v-stepper-step step="1" :complete="control.steps.step1.complete"></v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2" :complete="control.steps.step2.complete"></v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3" :complete="control.steps.step3.complete"></v-stepper-step>

            </v-stepper-header>

            <v-stepper-items>

                <v-stepper-content step="1">

                    <div class="body-1">
                        TODO
                    </div>

                    <div class="text-center">
                        <v-btn @click="$router.push('/')" primary outlined class="mx-5">
                            {{ $t('common.cancelBtn') }}
                        </v-btn>

                        <v-btn color="primary" @click="completeStep1" class="mx-5">
                            {{ $t('common.continueBtn') }}
                        </v-btn>
                    </div>

                </v-stepper-content>


                <v-stepper-content step="2">
                    <div class="body-1">
                        TODO
                    </div>

                    <div class="text-center">
                        <v-btn @click="$router.push('/')" primary outlined class="mx-5">
                            {{ $t('common.cancelBtn') }}
                        </v-btn>

                        <v-btn color="primary" @click="completeStep2" class="mx-5">
                            {{ $t('common.continueBtn') }}
                        </v-btn>
                    </div>
                </v-stepper-content>


                <v-stepper-content step="3">
                    <div class="body-1">
                        TODO
                    </div>

                    <div class="text-center">
                        <v-btn @click="$router.push('/')" primary outlined class="mx-5">
                            {{ $t('common.cancelBtn') }}
                        </v-btn>

                        <v-btn color="primary" @click="completeStep3" class="mx-5">
                            {{ $t('common.continueBtn') }}
                        </v-btn>
                    </div>
                </v-stepper-content>

            </v-stepper-items>
        </v-stepper>

    </div>
</template>


<script>
    export default {
        name: "ChangeEmail",
        data: () => ({
            control: {
                steps: {
                    current: 1,
                    step1: {
                        complete: false
                    },
                    step2: {
                        complete: false
                    },
                    step3: {
                        complete: false
                    }
                }
            }
        }),
        methods: {
            completeStep1(){
                this.control.steps.step1.complete = true;
                this.control.steps.current = 2;
            },

            completeStep2(){
                this.control.steps.step2.complete = true;
                this.control.steps.current = 3;
            },

            completeStep3(){
                this.control.steps.step3.complete = true;
                this.control.steps.current = 1;
            }
        }
    }
</script>


<style scoped>

</style>