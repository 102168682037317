
export default {
    translations: {
        mainPage: {
            languageLbl: 'Оберіть мову',
            titleLbl: 'Ласкаво просимо до Bunge MyAccess!',
            subtitleLbl: 'Що б ти хотів зробити?',
            activateAccountLbl: 'Активуйте рахунок',
            accountRecoveryLbl: 'Забули пароль...',
            changeEmailLbl: 'Змінити електронну адресу'
        },
        activateAccount: {
            titleLbl: 'Активація рахунку',
            residenceCountry: {
                titleLbl: 'Давайте отримаємо невелику інформацію про вас, щоб налаштувати свій обліковий запис. Не хвилюйтеся, ви налаштуєтеся за кілька хвилин!',
                selectCountryLbl: 'Яка ваша країна проживання?'
            },
            legalPolicy: {
                titleLbl: 'Договір про правову політику',
                confirmLbl: 'Я підтверджую, що прочитав, зрозумів і погоджуюся з умовами вище.'
            },
            provideKey: {
                titleLbl: 'Надайте ключ реєстрації',
                enrolmentKeyLbl: 'Використовуйте свій унікальний ключ реєстрації, наданий вам відділом кадрів. Це приватна інформація, не передавайте її нікому.'
            },
            confirmData: {
                titleLbl: 'Потрібно ще кілька деталей, і все готово!',
                disclaimerLbl: 'Bunge використовуватиме вашу особисту адресу електронної пошти з єдиною метою самообслуговування програм Bunge та скидання пароля. Бандж ніколи не використовуватиме цю інформацію будь-якого іншого типу зв\'язку.',
                confirmLbl: 'Я підтверджую, що наведені вище дані облікового запису є правильними',
                subscriptionLbl: 'Я хочу отримати доступ до програми Bunge GoConnect, щоб отримувати новини, інформаційні листи, повідомлення про Bunge та моє робоче середовище',
                form: {
                    firstName: 'Ім\'я',
                    lastName: 'Прізвищеe',
                    hireDate: 'Дата найму',
                    personExternalId: "Ідентифікаційний номер працівника Bunge",
                    emailAddress: 'Адреса електронної пошти',
                    password: 'Пароль',
                    passwordConfirm: 'Підтвердьте парольd',
                    emailConfirm: 'Підтвердьте електронну пошту',
                },
                confirmation: {
                    title: 'Активація рахунку',
                    text: 'Тепер ваш рахунок активовано. Будь ласка, почекайте.'
                },
                check: {
                    passw: 'Паролі не співпадають',
                    email: 'Електронні адреси не збігаються'
                },
                dialog: {
                    title: 'Підтвердьте активацію облікового запису',
                    subtitle: 'Тепер ваш рахунок буде активовано.',
                    firstName: 'Ім\'я',
                    lastName: 'Прізвище',
                    hireDate: 'Дата найму',
                    personExternalId: "Ідентифікаційний номер працівника Bunge",
                    email: 'Електронна пошта',
                    question: 'Ви впевнені, що хочете продовжити?'
                }
            },
            securityQuestion: {
                titleLbl: 'Встановіть таємне запитання та відповідь',
            },
            activationConfirm: {
                activatedLbl: 'Обліковий запис активовано!',
                titleLbl: 'Чудово, все готово!',
                confirmEmailLbl: 'Ми надіслали лист підтвердження на вашу електронну адресу',
                linkLbl: 'Ви знайдете посилання на програми Bunge.',
                thankYouLbl: 'Дякую!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: 'Будь ласка, введіть адресу електронної пошти, яку ви використовували під час реєстрації',
            },
            validateCode: {
                titleLbl: 'Перевірте одноразовий код підтвердження, надісланий на вашу електронну адресу. Зверніть увагу: цей код дійсний лише протягом 5 хвилин',
                contentLbl: 'Введіть той самий код підтвердження нижче'
            },
            validView: {
                titleLbl: 'Що б ти хотів зробити?',
                loginBtn: 'Отримати логін',
                passwordBtn: 'Скинути пароль'
            },
            loginView: {
                contentLbl: 'Ваш логін:'
            },
            passwordView: {
                headerLbl: 'Будь ласка, надайте новий пароль',
                sendRequestBtn: 'Підтвердити новий пароль',
                successPasswordLbl: 'Пароль облікового запису успішно змінено.',
                redirectLbl: 'Через кілька секунд ви будете перенаправлені на зовнішню сторінку.',
                redirectBtn: 'Перенаправлення'
            }
        },
        common: {
            continueBtn: 'Продовжуйте',
            backBtn: 'Назад',
            cancelBtn: 'Скасувати',
            downloadBtn: 'Завантажити',
            returnBtn: 'Повернутися на головну сторінку',
            confirmBtn: 'Підтвердьте',
            closeBtn: 'Закрити'
        },
        validation: {
            required: 'Це поле є обов\'язковим',
            email: 'Введіть дійсну електронну адресу',
            password: 'Будь ласка, введіть дійсний пароль (1 спеціальний символ: !@#$%^&*+=, 1 велика літера, 1 цифра, від 8 до 25 символів)'
        },
        apiErrors: {
            UNKNOWN_ERROR: 'Сталася невідома помилка',
            INVALID_ENROLLMENT_KEY_ERROR: 'Надано недійсний ключ реєстрації',
            ACCOUNT_NOT_FOUND_ERROR: 'Обліковий запис не знайдено',
            ACCOUNT_INACTIVE_ERROR: 'Обліковий запис неактивний',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: 'Рахунок вже активованоd',
            INVALID_EMAIL_FORMAT_ERROR: 'Недійсний формат електронної пошти',
            INVALID_EMAIL_ERROR: 'Введіть іншу електронну адресу',
            INVALID_PASSWORD_FORMAT_ERROR: 'Недійсний формат пароля (1 спеціальний символ: !@#$%^&*+=, 1 велика літера, 1 цифра, від 8 до 25 символів)',
            INVALID_VALIDATION_CODE: 'Ой! Це недійсний код. Будь ласка спробуйте ще раз',
            VALIDATION_CODE_EXPIRED: 'Вибачте! Термін дії коду перевірки минув. Будь ласка, відновіть код',
            INVALID_RECOVERY_TOKEN: 'Ой! Термін дії сеансу "Забули пароль" закінчився. Будь ласка, почніть знову',
            MAX_ATTEMPTS: 'О ні! Ви перевищили кількість спроб. Будь ласка, почніть знову'
        },
        versioning: {
            title: 'Журнал змін версій',
            version: 'Версія',
            description: 'Опис змін',
        },
        idle: {
            title: 'Сесія закінчилась',
            info1: 'Сесія закінчилась через тривалий період бездіяльності.',
            info2: 'Програма перезавантажиться через {value} секунд',
            reload: 'Перезавантажте зараз'
        }
    }
}
