<template>
    <div class="step-content">

        <div class="body-1 font-weight-bold mb-5">
            {{ $t('activateAccount.confirmData.titleLbl') }}
        </div>

        <div>
            <v-form v-model="form.valid">

                <v-row>
                    <v-col cols="12" sm="4">
                        <v-text-field v-model="form.info.firstName"
                                      :label="$t('activateAccount.confirmData.form.firstName')"
                                      dense disabled>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-text-field v-model="form.info.lastName"
                                      :label="$t('activateAccount.confirmData.form.lastName')"
                                      dense disabled>
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field v-model="form.info.hireDate"
                                  :label="$t('activateAccount.confirmData.form.hireDate')"
                                  dense disabled>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field v-model="form.info.personExternalId"
                                  :label="$t('activateAccount.confirmData.form.personExternalId')"
                                  dense disabled>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                                  :data-cy="'account-confirmation-field-email1'"
                                  v-model="form.editable.email1"
                                  @input="checkEmails"
                                  :rules="rules.required.concat(rules.email)"
                                  autocomplete="email"
                                  dense>

                        <template v-slot:append>
                            <v-icon tabindex="-1" v-show="!!form.editable.email1"
                                    @click="form.editable.email1 = null">mdi-close</v-icon>
                        </template>

                        <template #label>
                          {{ $t('activateAccount.confirmData.form.emailAddress') }}<span class="error--text"><strong> *</strong></span>
                        </template>

                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                                  :data-cy="'account-confirmation-field-email2'"
                                  v-model="form.editable.email2"
                                  @input="checkEmails"
                                  :rules="rules.required.concat(rules.email)"
                                  autocomplete="email"
                                  dense>

                        <template v-slot:append>
                            <v-icon tabindex="-1" v-show="!!form.editable.email2"
                                    @click="form.editable.email2 = null">mdi-close</v-icon>
                        </template>

                        <template #label>
                          {{ $t('activateAccount.confirmData.form.emailConfirm') }}<span class="error--text"><strong> *</strong></span>
                        </template>

                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                                  :data-cy="'account-confirmation-field-password1'"
                                  v-model="form.editable.password1"
                                  @input="checkPasswords"
                                  :rules="rules.required.concat(rules.password)"
                                  type="password"
                                  autocomplete="new-password"
                                  :label="$t('activateAccount.confirmData.form.password')"
                                  oncopy="return false" onpaste="return false"
                                  dense>

                        <template v-slot:append>
                            <v-icon tabindex="-1" v-show="!!form.editable.password1"
                                    @click="form.editable.password1 = null">mdi-close</v-icon>
                        </template>

                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                                  :data-cy="'account-confirmation-field-password2'"
                                  v-model="form.editable.password2"
                                  @input="checkPasswords"
                                  :rules="rules.required.concat(rules.password)"
                                  type="password"
                                  autocomplete="new-password"
                                  :label="$t('activateAccount.confirmData.form.passwordConfirm')"
                                  oncopy="return false" onpaste="return false"
                                  dense>

                        <template v-slot:append>
                            <v-icon tabindex="-1" v-show="!!form.editable.password2"
                                    @click="form.editable.password2 = null">mdi-close</v-icon>
                        </template>

                    </v-text-field>
                  </v-col>
                </v-row>

                <v-fade-transition :hide-on-leave="true">
                    <div v-show="errors.passwordConfirm || errors.emailConfirm || errors.otherMessages.length"
                         class="error--text font-weight-bold my-1 mx-auto text-center error-msg">

                        <div>
                            {{ errors.passwordConfirm }}
                        </div>

                        <div>
                            {{ errors.emailConfirm }}
                        </div>

                        <div v-for="error in errors.otherMessages" :key="error">
                            {{ $t(`apiErrors.${error}`) }}
                        </div>
                    </div>
                </v-fade-transition>

                <v-row align="center">
                    <v-col cols="12">
                      <p class="my-2">
                          {{ $t('activateAccount.confirmData.disclaimerLbl') }}
                      </p>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-checkbox
                                    :data-cy="'account-confirmation-checkbox'"
                                    v-model="form.editable.confirm"
                                    :label="$t('activateAccount.confirmData.confirmLbl')"
                                    hide-details class="mt-0">
                        </v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-checkbox
                                :data-cy="'account-subscription-checkbox'"
                                v-model="form.editable.subscription"
                                :label="$t('activateAccount.confirmData.subscriptionLbl')"
                                hide-details class="mt-0">
                        </v-checkbox>
                    </v-col>

                    <v-spacer></v-spacer>
                </v-row>

            </v-form>
        </div>

        <v-divider class="my-5"></v-divider>

        <v-row>
            <v-col cols="12" sm="6" class="text-left">
                <v-btn @click="$router.push('/')" primary outlined>
                    <v-icon>mdi-close</v-icon><span class="ml-1">{{ $t('common.cancelBtn') }}</span>
                </v-btn>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
                <v-btn color="primary" @click="back" outlined class="mr-10">
                    <v-icon>mdi-arrow-left</v-icon><span class="ml-1">{{ $t('common.backBtn') }}</span>
                </v-btn>

                <v-btn :data-cy="'account-confirmation-continue-button'" color="primary" @click="complete" :disabled="!form.editable.confirm || !form.valid || !!errors.passwordConfirm || !!errors.emailConfirm">
                    <v-icon>mdi-check</v-icon><span class="ml-1">{{ $t('common.continueBtn') }}</span>
                </v-btn>
            </v-col>
        </v-row>


        <confirm-activation-dialog-component ref="confirmActivationDialog" persistent>
            <!---->
        </confirm-activation-dialog-component>


        <loading-dialog-component ref="loadingDialog" persistent>
            <!---->
        </loading-dialog-component>

    </div>
</template>


<script>

    import ConfirmActivationDialogComponent from "@/views/activate-account/confirm-activation-dialog/ConfirmActivationDialog.vue";
    import LoadingDialogComponent from "@/components/utilities/loading.dialog.component";
    import ValidationRulesUtils from "@/utilities/validation.rules";
    import BackendService from "@/services/backend.service";
    import ErrorHandler from "@/utilities/error.handler";

    export default {
        name: "ActivateAccountConfirmData",
        components: {
            ConfirmActivationDialogComponent,
            LoadingDialogComponent
        },
        data: () => ({
            control: {
                processing: false
            },
            form: {
                info: {
                    firstName: null,
                    lastName: null,
                    hireDate: null,
                    personExternalId: null
                },
                editable: {
                    email1: null,
                    email2: null,
                    password1: null,
                    password2: null,
                    subscription: true,
                    confirm: false
                },
                valid: false
            },
            errors: {
                passwordConfirm: null,
                emailConfirm: null,
                otherMessages: []
            }
        }),
        computed: {
            rules(){
                return ValidationRulesUtils;
            }
        },
        methods: {

            init(userData) {
                this.form.info.firstName = userData.firstName;
                this.form.info.lastName = userData.lastName;
                this.form.info.hireDate = userData.hireDate;
                this.form.info.personExternalId = userData.externalId;
            },

            back(){
                this.$emit('back');
            },

            complete(){
              if (this.form.editable.confirm) {
                  this.$refs.confirmActivationDialog.show({
                      details: {
                          firstName: this.form.info.firstName,
                          lastName: this.form.info.lastName,
                          hireDate: this.form.info.hireDate,
                          personExternalId: this.form.info.personExternalId,
                          email: this.form.editable.email1
                      }
                }, this.activate);
              }
            },

            activate(){
                this.$refs.loadingDialog.show({
                    title: this.$i18n.t('activateAccount.confirmData.confirmation.title'),
                    text: this.$i18n.t('activateAccount.confirmData.confirmation.text')
                });

                this.control.processing = true;
                this.errors.otherMessages = [];

                BackendService.activateAccount(
                    this.form.editable.email1,
                    this.form.editable.password1,
                    this.form.editable.subscription,
                    this.$i18n.locale
                )
                .then(() => {
                    this.$emit('complete');
                    this.$emit('user-data', this.form.editable.email1);
                })
                .catch(error => {
                    this.errors.otherMessages = ErrorHandler.extractErrorTypes(error);
                })
                .finally(() => {
                    this.$refs.loadingDialog.hide();
                    this.control.processing = false;
                });
            },

            checkPasswords(){
                this.errors.passwordConfirm = this.form.editable.password1 !== this.form.editable.password2?
                    this.$i18n.t('activateAccount.confirmData.check.passw') : null;
            },

            checkEmails(){
              this.errors.emailConfirm = this.form.editable.email1 !== this.form.editable.email2?
                  this.$i18n.t('activateAccount.confirmData.check.email') : null;
            }
        }
    }
</script>
