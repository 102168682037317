
import VueI18n from '@/plugins/i18n';

const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PASSWORD_REGEXP = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*+=])(?=\S+$).{8,25}$/;

export default {

    required: [
        v => !!v || VueI18n.t('validation.required')
    ],

    email: [
        v => EMAIL_REGEXP.test(v) || VueI18n.t('validation.email')
    ],

    password: [
        v => PASSWORD_REGEXP.test(v) || VueI18n.t('validation.password')
    ]
};