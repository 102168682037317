
export const HTTP_STATUS_403 = 403;

export const OVERLAY_TIME = 3000;

export const LEGAL_POLICY_FILES = {
    'wFP7pu': 'Access request form For WebSite  Apps_HR.global_INDIA (clean final version)_V2.pdf',
    'Gqwc5X': 'TERMO DE SOLICITACAO PARA ACESSO AO SITE E APP_Brasil_V2.pdf',
    'hPspqE': 'Bunge Apps Consent Form.May 2021_US_CANADA_V2.pdf',
    'w5cHV7': 'FORMULARIO DE SOLICITUD DE ACCESO AL SITIO WEB Y APP_espanol - South Cone_MX_CO_GUA_PE_V2.pdf',
    'CXrafZ': 'User Agreement_Simplified Chinese.pdf',
    '5BAZaE': 'User Agreement_English.pdf',
    '4bwrIQ': '0365 MFA Draft Email (2021.11.17) (AU) (clean).pdf',
    'nsdT2w': 'Finnish User agreement UUSI (1).pdf',
    'Q2UfD4': 'Hungarian User Agreement_v4_final.pdf',
    'qnEiAd': 'Italian User agreement (2).pdf',
    'Ti8nYX': 'Romanian User agreement (1).pdf',
    'zVSVLW': 'English User Ag for MY Draft Email (2021.11.23) (MY).pdf',
    'rczNH6': 'Malay User Ag Draft Email (2021.11.23) (MY) (clean).pdf',
    'jQRln1': 'Polish User Agreement (004).pdf',
    'NaXp88': 'Access+request+form+For+WebSite++Apps-Turkey+20210521.pdf',
    'PWffHQ': 'Turkish User Agreement revised.pdf',
    'nBgJKe': 'Access request form For WebSite  Apps_EU and Switzerland clean 20231130.pdf',
    'tNgGHe': 'Access request form For WebSite Apps_EU and Switzerland clean 20231130 (German).pdf',
    'dfF5Gb': 'Access request form For WebSite Apps_EU and Switzerland clean 20231130 (Ukrainian).pdf'
}

export const LEGAL_POLICY_FILES_MAPPING = [
    {
        country: 'Argentina',
        code: 'ar',
        document: {
            eng: null,
            nat: {name: 'Spanish', value: 'w5cHV7'}
        }
    },
    {
        country: 'Australia',
        code: 'au',
        document: {
            eng: {name: 'English', value: '4bwrIQ'},
            nat: null
        }
    },
    {
        country: 'Brazil',
        code: 'br',
        document: {
            eng: null,
            nat: {name: 'Portuguese', value: 'Gqwc5X'}
        }
    },
    {
        country: 'Burkina Faso',
        code: 'bf',
        document: {
            eng: {name: 'English', value: '5BAZaE'},
            nat: null
        }
    },
    {
        country: 'Canada',
        code: 'ca',
        document: {
            eng: {name: 'English', value: 'hPspqE'},
            nat: null
        }
    },
    {
        country: 'China',
        code: 'cn',
        document: {
            eng: {name: 'English', value: '5BAZaE'},
            nat: {name: 'Chinese', value: 'CXrafZ'},
        }
    },
    {
        country: 'Colombia',
        code: 'co',
        document: {
            eng: null,
            nat: {name: 'Spanish', value: 'w5cHV7'}
        }
    },
    {
        country: 'Egypt',
        code: 'eg',
        document: {
            eng: {name: 'English', value: '5BAZaE'},
            nat: null
        }
    },
    {
        country: 'Finland',
        code: 'fi',
        document: {
            eng: {name: 'English', value: '5BAZaE'},
            nat: {name: 'Finnish', value: 'nsdT2w'}
        }
    },
    {
        country: 'Germany',
        code: 'de',
        document: {
            eng: {name: 'English', value: 'nBgJKe'},
            nat: {name: 'German', value: 'tNgGHe'}
        }
    },
    {
        country: 'Ghana',
        code: 'gh',
        document: {
            eng: {name: 'English', value: '5BAZaE'},
            nat: null
        }
    },
    {
        country: 'Guatemala',
        code: 'gt',
        document: {
            eng: null,
            nat: {name: 'Spanish', value: 'w5cHV7'}
        }
    },
    {
        country: 'Hungary',
        code: 'hu',
        document: {
            eng: {name: 'English', value: '5BAZaE'},
            nat: {name: 'Hungarian', value: 'Q2UfD4'}
        }
    },
    {
        country: 'India',
        code: 'in',
        document: {
            eng: {name: 'English', value: 'wFP7pu'},
            nat: null
        }
    },
    {
        country: 'Italy',
        code: 'it',
        document: {
            eng: {name: 'English', value: '5BAZaE'},
            nat: {name: 'Italian', value: 'qnEiAd'}
        }
    },
    {
        country: 'Malaysia',
        code: 'my',
        document: {
            eng: {name: 'English', value: 'zVSVLW'},
            nat: {name: 'Malaysian', value: 'rczNH6'}
        }
    },
    {
        country: 'Mexico',
        code: 'mx',
        document: {
            eng: null,
            nat: {name: 'Spanish', value: 'w5cHV7'}
        }
    },
    {
        country: 'Peru',
        code: 'pe',
        document: {
            eng: null,
            nat: {name: 'Spanish', value: 'w5cHV7'}
        }
    },
    {
        country: 'Poland',
        code: 'pl',
        document: {
            eng: {name: 'English', value: '5BAZaE'},
            nat: {name: 'Polish', value: 'jQRln1'}
        }
    },
    {
        country: 'Romania',
        code: 'ro',
        document: {
            eng: {name: 'English', value: '5BAZaE'},
            nat: {name: 'Romanian', value: 'Ti8nYX'}
        }
    },
    {
        country: 'Switzerland',
        code: 'ch',
        document: {
            eng: {name: 'English', value: '5BAZaE'},
            nat: null
        }
    },
    {
        country: 'Turkey',
        code: 'tr',
        document: {
            eng: {name: 'English', value: 'NaXp88'},
            nat: {name: 'Turkish', value: 'PWffHQ'}
        }
    },
    {
        country: 'Ukraine',
        code: 'ua',
        document: {
            eng: {name: 'English', value: 'nBgJKe'},
            nat: {name: 'Ukrainian', value: 'dfF5Gb'}
        }
    },
    {
        country: 'United States',
        code: 'us',
        document: {
            eng: {name: 'English', value: 'hPspqE'},
            nat: null
        }
    }
]
