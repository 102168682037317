
export default {
    translations: {
        mainPage: {
            languageLbl: 'Valitse kieli',
            titleLbl: 'Tervetuloa Bunge MyAccess!',
            subtitleLbl: 'Mitä haluaisit tehdä?',
            activateAccountLbl: 'Aktivoi tili',
            accountRecoveryLbl: 'Unohdin salasanani..',
            changeEmailLbl: 'Vaihda sähköposti'
        },
        activateAccount: {
            titleLbl: 'Tilin aktivointi',
            residenceCountry: {
                titleLbl: 'Kerätään sinusta hieman tietoja, jotta pääset alkuun. Älä huoli olet valmis muutamassa minuutissa!',
                selectCountryLbl: 'Mikä on asuinmaasi?'
            },
            legalPolicy: {
                titleLbl: 'Lainvaatimustenmukaisuusseloste',
                confirmLbl: 'Vahvistan että olen lukenut, ymmärtänyt ja hyväksynyt edellä mainitut ehdot.'
            },
            provideKey: {
                titleLbl: 'Anna rekisteröitymissavain',
                enrolmentKeyLbl: 'Käytä yksilöllistä rekisteröitymissavainta, jonka saat henkilöstöosastoltasi.  Älä jaa avainta kenenkään muun kanssa.'
            },
            confirmData: {
                titleLbl: 'Vahvista tilitiedot',
                disclaimerLbl: 'Bunge käyttää henkilökohtaista sähköpostiosoitettasi yksinomaan Bunge-sovellusten itsepalvelukäyttöön ja salasanan palauttamiseen. Bunge ei koskaan käytä näitä tietoja muun tyyppisessä viestinnässä.',
                confirmLbl: 'Vahvistan yllä olevien tilitietojen olevan oikein',
                subscriptionLbl: 'Haluan saada Bunge GoConnect -sovelluksesta uutisia, uutiskirjeitä, viestintää Bungesta ja työympäristöstäni',
                form: {
                    firstName: 'Etunimi',
                    lastName: 'Sukunimi',
                    hireDate: 'Työsuhteen alkupäivä',
                    personExternalId: "Bunge työntekijän tunnus",
                    emailAddress: 'Sähköpostiosoite',
                    password: 'Salasana',
                    passwordConfirm: 'Vahvista salasana',
                    emailConfirm: 'Vahvista sähköposti',
                },
                confirmation: {
                    title: 'Tilin aktivointi',
                    text: 'Tilisi on nyt aktivoitu. Odota ole hyvä.'
                },
                check: {
                    passw: 'Salasanat eivät täsmää',
                    email: 'Sähköpostiosoitteet eivät täsmää'
                },
                dialog: {
                    title: 'Vahvista tilin aktivointi',
                    subtitle: 'Tilisi aktivoidaan nyt.',
                    firstName: 'Etunimi',
                    lastName: 'Sukunimi',
                    hireDate: 'Työsuhteen alkupäivä',
                    personExternalId: "Bunge työntekijän tunnus",
                    email: 'Sähköposti',
                    question: 'Haluatko varmasti jatkaa?'
                }
            },
            securityQuestion: {
                titleLbl: 'Anna turvakysymys ja turvakysymyksen vastaus',
            },
            activationConfirm: {
                activatedLbl: 'Tili aktivoitu!',
                titleLbl: 'Hienoa olet valmis!',
                confirmEmailLbl: 'Lähetimme vahvistussähköpostin sähköpostiosoitteeseesi',
                linkLbl: 'Löydät linkkejä Bunge-sovelluksiin.',
                thankYouLbl: 'Kiitos!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: 'Anna rekisteröitymisen yhteydessä käyttämäsi sähköpostiosoite.',
            },
            validateCode: {
                titleLbl: 'Tarkista, onko sähköpostiosoitteeseesi lähetetty kertaluonteinen vahvistuskoodi. Huomaa: Tämä koodi on voimassa vain 5 minuuttia',
                contentLbl: 'Anna sama vahvistuskoodi alle'
            },
            validView: {
                titleLbl: 'Mitä haluaisit tehdä?',
                loginBtn: 'Hae kirjautuminen',
                passwordBtn: 'Nollaa salasana'
            },
            loginView: {
                contentLbl: 'Kirjautumistunnuksesi on:'
            },
            passwordView: {
                headerLbl: 'Anna uusi salasana',
                sendRequestBtn: 'Vahvista uusi salasana',
                successPasswordLbl: 'Tilin salasana vaihdettiin onnistuneesti.',
                redirectLbl: 'Muutaman sekunnin kuluttua sinut ohjataan ulkoiselle sivulle.',
                redirectBtn: 'Uudelleenohjaus'
            }
        },
        common: {
            continueBtn: 'Jatka',
            backBtn: 'Takaisin',
            cancelBtn: 'Peruuta',
            downloadBtn: 'Lataa',
            returnBtn: 'Palaa pääsivulle',
            confirmBtn: 'Vahvista',
            closeBtn: 'Kiinni'
        },
        validation: {
            required: 'Tämä kenttä pitää täyttää',
            email: 'Anna voimassa oleva sähköpostiosoite',
            password: 'Anna voimassa oleva salasana (1 erikoismerkki !@#$%^&*+=, 1 iso kirjain, 1 numero, 8-25 merkkiä)'
        },
        apiErrors: {
            UNKNOWN_ERROR: 'Tapahtui tuntematon virhe',
            INVALID_ENROLLMENT_KEY_ERROR: 'Annettu virheellinen rekisteröitymisavain',
            ACCOUNT_NOT_FOUND_ERROR: 'Tiliä ei löydy',
            ACCOUNT_INACTIVE_ERROR: 'Tili ei ole aktiivinen',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: 'Tili on jo aktivoitu',
            INVALID_EMAIL_FORMAT_ERROR: 'Virheellinen sähköpostimuoto',
            INVALID_EMAIL_ERROR: 'Anna toinen sähköpostiosoite',
            INVALID_PASSWORD_FORMAT_ERROR: 'Virheellinen salasanan muoto (1 erikoismerkki: !@#$%^&*+=, 1 isoa kirjainta, 1 numero, 8-25 merkkiä)',
            INVALID_VALIDATION_CODE: 'Oho! Tämä on virheellinen koodi. Yritä uudelleen',
            VALIDATION_CODE_EXPIRED: 'Anteeksi! Vahvistuskoodi on vanhentunut. Ole hyvä ja luo koodi uudelleen',
            INVALID_RECOVERY_TOKEN: 'Oho! Unohtunut salasana -istuntosi on vanhentunut. Aloita ystävällisesti uudelleen',
            MAX_ATTEMPTS: 'Voi ei! Ylitit yritysten määrän. Aloita ystävällisesti uudelleen'
        },
        versioning: {
            title: 'Versiomuutosloki',
            version: 'Versio',
            description: 'Muutosten kuvaus',
        },
        idle: {
            title: 'Istunto päättyi',
            info1: 'Istunto on päättynyt pitkän käyttämättömyyden vuoksi.',
            info2: 'Sovellus ladataan uudelleen {value} sekunnin kuluttua',
            reload: 'Lataa uudelleen nyt'
        }
    }
}
