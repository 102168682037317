
export default {
    translations: {
        mainPage: {
            languageLbl: 'Wybierz język',
            titleLbl: 'Witamy w Bunge MyAccess!',
            subtitleLbl: 'Co chciałbyś zrobić?',
            activateAccountLbl: 'Aktywować konto',
            accountRecoveryLbl: 'Nie pamiętam hasła...',
            changeEmailLbl: 'Zmienić e-mail'
        },
        activateAccount: {
            titleLbl: 'Aktywacja konta',
            residenceCountry: {
                titleLbl: 'Uzyskajmy trochę informacji o Tobie, abyś mógł rozpocząć pracę. Jeszcze tylko kilka kliknięć!',
                selectCountryLbl: 'Jaki jest Twój kraj zamieszkania?'
            },
            legalPolicy: {
                titleLbl: 'Umowa dotycząca polityki prawnej',
                confirmLbl: 'Potwierdzam, że przeczytałem, zrozumiałem i zgadzam się z powyższymi warunkami.'
            },
            provideKey: {
                titleLbl: 'Przekazać klucz zapisu',
                enrolmentKeyLbl: 'Użyj unikalnego klucza dostępu, który został Ci przekazany przez dział kadr. Nie udostępniaj tego klucza innym osobom.'
            },
            confirmData: {
                titleLbl: 'Jeszcze kilka szczegółów i wszystko gotowe!',
                disclaimerLbl: 'Twój osobisty adres e-mail będzie wykorzystywany przez Bunge wyłącznie w celu samoobsługowego dostępu do aplikacji Bunge i resetowania hasła. Bunge nigdy nie będzie wykorzystywać tych informacji do innego rodzaju komunikacji.',
                confirmLbl: 'Potwierdzam, że powyższe dane dotyczące konta są poprawne',
                subscriptionLbl: 'Chcę mieć dostęp do aplikacji Bunge GoConnect, aby otrzymywać wiadomości, biuletyny, komunikaty dotyczące Bunge i mojego środowiska pracy.',
                form: {
                    firstName: 'Imię',
                    lastName: 'Nazwisko',
                    hireDate: 'Data zatrudnienia',
                    personExternalId: 'Identyfikator pracownika Bunge',
                    emailAddress: 'Adres e-mail',
                    password: 'Hasło',
                    passwordConfirm: 'Potwierdź hasło',
                    emailConfirm: 'Potwierdź e-mail',
                },
                confirmation: {
                    title: 'Aktywacja konta',
                    text: 'Twoje konto jest teraz aktywowane. Proszę czekać.'
                },
                check: {
                    passw: 'Hasła nie pasują do siebie',
                    email: 'Nie zgadzają się e-maile'
                },
                dialog: {
                    title: 'Potwierdź aktywację konta',
                    subtitle: 'Twoje konto zostanie teraz aktywowane.',
                    firstName: 'Imię',
                    lastName: 'Nazwisko',
                    hireDate: 'Data zatrudnienia',
                    personExternalId: 'Identyfikator pracownika Bunge',
                    email: 'E-mail',
                    question: 'Czy na pewno chcesz kontynuować?'
                }
            },
            securityQuestion: {
                titleLbl: 'Ustaw pytanie zabezpieczające i odpowiedź'
            },
            activationConfirm: {
                activatedLbl: 'Konto aktywowane!',
                titleLbl: 'Świetnie, wszystko gotowe!',
                confirmEmailLbl: 'Na Twój adres e-mail {value} wysłaliśmy potwierdzenie zawierające wszystkie potrzebne informacje.',
                linkLbl: 'Znajdziesz linki do aplikacji Bunge.',
                thankYouLbl: 'Dziękujemy!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: 'Wpisz adres e-mail, którego użyłeś podczas rejestracji.',
            },
            validateCode: {
                titleLbl: 'Sprawdź, czy na Twój adres e-mail został wysłany jednorazowy kod weryfikacyjny. Uwaga: ten kod jest ważny tylko przez 5 minut',
                contentLbl: 'Wprowadź ten sam kod weryfikacyjny poniżej'
            },
            validView: {
                titleLbl: 'Co chciałbyś robić?',
                loginBtn: 'Odzyskaj login',
                passwordBtn: 'Zresetuj hasło'
            },
            loginView: {
                contentLbl: 'Twój login to:'
            },
            passwordView: {
                headerLbl: 'Podaj nowe hasło',
                sendRequestBtn: 'Potwierdź nowe hasło',
                successPasswordLbl: 'Hasło do konta zostało pomyślnie zmienione.',
                redirectLbl: 'Za kilka sekund zostaniesz przekierowany na stronę zewnętrzną.',
                redirectBtn: 'Przekierowanie'
            }
        },
        common: {
            continueBtn: 'Kontynuuj',
            backBtn: 'Powrót',
            cancelBtn: 'Anuluj',
            downloadBtn: 'Pobierz',
            returnBtn: 'Wróć do strony głównej',
            confirmBtn: 'Potwierdź',
            closeBtn: 'Zamknij'
        },
        validation: {
            required: 'To pole jest wymagane',
            email: 'Podaj prawidłowy adres e-mail',
            password: 'Proszę podać prawidłowe hasło (1 znak specjalny: !@#$%^&*+=, 1 wielka litera, 1 cyfra, od 8 do 25 znaków)'
        },
        apiErrors: {
            UNKNOWN_ERROR: 'Wystąpił nieznany błąd',
            INVALID_ENROLLMENT_KEY_ERROR: 'Podano nieprawidłowy klucz zapisu',
            ACCOUNT_NOT_FOUND_ERROR: 'Nie znaleziono konta',
            ACCOUNT_INACTIVE_ERROR: 'Konto jest nieaktywne',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: 'Konto jest już aktywne',
            INVALID_EMAIL_FORMAT_ERROR: 'Nieprawidłowy format adresu e-mail',
            INVALID_EMAIL_ERROR: 'Proszę podać inny adres e-mail',
            INVALID_PASSWORD_FORMAT_ERROR: 'Nieprawidłowy format hasła (1 znak specjalny: !@#$%^&*+=, 1 wielka litera, 1 cyfra, od 8 do 25 znaków)',
            INVALID_VALIDATION_CODE: 'Ups! To jest nieprawidłowy kod. Proszę spróbuj ponownie',
            VALIDATION_CODE_EXPIRED: 'Przepraszamy! Kod weryfikacyjny wygasł. Proszę wygenerować kod ponownie',
            INVALID_RECOVERY_TOKEN: 'Ups! Twoja sesja „Zapomniałem hasła” wygasła. Proszę zacząć od nowa',
            MAX_ATTEMPTS: 'O nie! Przekroczono liczbę prób. Proszę zacząć od nowa'
        },
        versioning: {
            title: 'Dziennik zmian wersji',
            version: 'Wersja',
            description: 'Opis zmian'
        },
        idle: {
            title: 'Sesja wygasła',
            info1: 'Sesja wygasła z powodu długiego okresu bezczynności.',
            info2: 'Aplikacja zostanie ponownie załadowana po {value} sekundach.',
            reload: 'Odśwież teraz'
        }
    }
}
