import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#043C5A',
                secondary: '#3C647D',
                accent: '#9BC83C',
                error: '#BC4F55'
            }
        }
    },
});