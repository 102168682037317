
export default {
    translations: {
        mainPage: {
            languageLbl: 'Selezionare la lingua',
            titleLbl: 'Benvenuto su Bunge MyAccess!',
            subtitleLbl: 'Cosa vorresti fare?',
            activateAccountLbl: 'Attivare l\'account',
            accountRecoveryLbl: 'Password dimenticata...',
            changeEmailLbl: 'Cambiare l\'email'
        },
        activateAccount: {
            titleLbl: 'Attivazione dell\'account',
            residenceCountry: {
                titleLbl: 'Prendiamo alcune informazioni su di te per iniziare. Solo qualche altro clic!',
                selectCountryLbl: 'Qual è il tuo paese di residenza?'
            },
            legalPolicy: {
                titleLbl: 'Accordo di politica legale',
                confirmLbl: 'Confermo di aver letto, compreso e accettato le condizioni di cui sopra'
            },
            provideKey: {
                titleLbl: 'Fornire la chiave di iscrizione',
                enrolmentKeyLbl: 'Usa la chiave d\'iscrizione unica che ti è stata fornita dalle tue Risorse Umane. Si prega di non condividere la chiave con nessun altro.'
            },
            confirmData: {
                titleLbl: 'Ancora un paio di dettagli e sei a posto!',
                disclaimerLbl: 'Il tuoo indirizzo e-mail personale sarà utilizzato da Bunge al solo scopo di accesso self service alle applicazioni Bunge e alla reimpostazione della password. Bunge non userà mai queste informazioni per qualsiasi altro tipo di comunicazione.',
                confirmLbl: 'Confermo che i dati dell\'account di cui sopra sono corretti',
                subscriptionLbl: 'Desidero accedere all\'applicazione Bunge GoConnect per ricevere notizie, newsletter, comunicazioni su Bunge e il mio ambiente di lavoro',
                form: {
                    firstName: 'Nome di battesimo',
                    lastName: 'Cognome',
                    hireDate: 'Data di assunzione',
                    personExternalId: 'ID dipendente Bunge',
                    emailAddress: 'Indirizzo e-mail',
                    password: 'Password',
                    passwordConfirm: 'Confermare la password',
                    emailConfirm: 'Conferma email',
                },
                confirmation: {
                    title: 'Attivazione dell\'account',
                    text: 'Il tuo account è stato attivato. Si prega di attendere.'
                },
                check: {
                    passw: 'Le password non corrispondono',
                    email: 'Le e-mail non corrispondono'
                },
                dialog: {
                    title: 'Conferma l\'attivazione dell\'account',
                    subtitle: 'Il tuo account sarà ora attivato.',
                    firstName: 'Nome',
                    lastName: 'Cognome',
                    hireDate: 'Data di assunzione',
                    personExternalId: 'ID dipendente Bunge',
                    email: 'Email',
                    question: 'Sei sicuro di voler procedere?'
                }
            },
            securityQuestion: {
                titleLbl: 'Imposta domanda e risposta di sicurezza'
            },
            activationConfirm: {
                activatedLbl: 'Conto attivato!',
                titleLbl: 'Bene, sei a posto!',
                confirmEmailLbl: 'Abbiamo inviato un\'e-mail di conferma con tutte le informazioni necessarie al tuo indirizzo e-mail {value}.',
                linkLbl: 'Troverai collegamenti alle applicazioni Bunge.',
                thankYouLbl: 'Grazie!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: 'Inserisci l\'indirizzo email che hai usato per la registrazione',
            },
            validateCode: {
                titleLbl: 'Controlla se è stato inviato un codice di convalida una tantum al tuo indirizzo email. Nota gentile: questo codice è valido solo per 5 minuti',
                contentLbl: 'Inserisci lo stesso codice di convalida di seguito'
            },
            validView: {
                titleLbl: 'Cosa ti piacerebbe fare?',
                loginBtn: 'Recupera accesso',
                passwordBtn: 'Resetta la password'
            },
            loginView: {
                contentLbl: 'Il tuo accesso è:'
            },
            passwordView: {
                headerLbl: 'Si prega di fornire la nuova password',
                sendRequestBtn: 'Conferma la nuova password',
                successPasswordLbl: 'La password dell\'account è stata modificata correttamente.',
                redirectLbl: 'In pochi secondi verrai reindirizzato alla pagina esterna.',
                redirectBtn: 'Reindirizzare'
            }
        },
        common: {
            continueBtn: 'Continua',
            backBtn: 'Indietro',
            cancelBtn: 'Annulla',
            downloadBtn: 'Scarica',
            returnBtn: 'Torna alla pagina principale',
            confirmBtn: 'Conferma',
            closeBtn: 'Chiudi'
        },
        validation: {
            required: 'Questo campo è obbligatorio',
            email: 'Si prega di fornire un indirizzo e-mail valido',
            password: 'Si prega di fornire una password valida (1 carattere speciale: !@#$%^&*+=, 1 lettera maiuscola, 1 cifra, da 8 a 25 caratteri)'
        },
        apiErrors: {
            UNKNOWN_ERROR: 'Si è verificato un errore sconosciuto',
            INVALID_ENROLLMENT_KEY_ERROR: 'Chiave di iscrizione fornita non valida',
            ACCOUNT_NOT_FOUND_ERROR: 'Account non trovato',
            ACCOUNT_INACTIVE_ERROR: 'Account inattivo',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: 'Account già attivato',
            INVALID_EMAIL_FORMAT_ERROR: 'Formato email non valido',
            INVALID_EMAIL_ERROR: 'Si prega di fornire un indirizzo email diverso',
            INVALID_PASSWORD_FORMAT_ERROR: 'Formato password non valido (1 carattere speciale: !@#$%^&*+=, 1 lettera maiuscola, 1 cifra, da 8 a 25 caratteri)',
            INVALID_VALIDATION_CODE: 'Ops! Questo è un codice non valido. Per favore riprova',
            VALIDATION_CODE_EXPIRED: 'Scusa! Il codice di convalida è scaduto. Per favore rigenera il codice',
            INVALID_RECOVERY_TOKEN: 'Ops! La tua sessione "Password dimenticata" è scaduta. Per favore, ricomincia da capo',
            MAX_ATTEMPTS: 'Oh no! Hai superato il numero di tentativi. Per favore, ricomincia da capo'
        },
        versioning: {
            title: 'Registro dei cambiamenti di versione',
            version: 'Versione',
            description: 'Descrizione delle modifiche'
        },
        idle: {
            title: 'Sessione scaduta',
            info1: 'La sessione è scaduta a causa di un lungo periodo di inattività.',
            info2: 'L\'applicazione verrà ricaricata fra {value} secondi',
            reload: 'Ricarica ora'
        }
    }
}
