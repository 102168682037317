<template>
    <div>
        <v-fade-transition :hide-on-leave="true">
            <v-col cols="12">

                <div class="body-1 font-weight-bold mb-5">
                    {{ $t('recoveryCredentials.provideEmail.contentLbl') }}
                </div>

                <div>
                    <v-form v-model="control.valid">
                        <v-text-field :data-cy="'recover-credentials-email-field'"
                                      v-model="form.email"
                                      :rules="rules.required.concat(rules.email)"
                                      :disabled="control.processing"
                                      outlined autocomplete="email" class="mt-10">
                        </v-text-field>
                    </v-form>
                </div>

                <v-row>
                    <v-col class="text-left">
                        <v-btn @click="$router.push('/')"
                               outlined class="credentials-button py-5"
                               :disabled="control.processing"
                               :data-cy="'recover-credentials-cancel-btn'">
                            <v-icon size="25">mdi-close</v-icon>
                            <span class="ml-3">{{ $t('common.cancelBtn') }}</span>
                        </v-btn>
                    </v-col>

                    <v-col class="text-center">
                        <div v-show="control.processing">
                            <v-progress-circular indeterminate color="primary" :width="3" :size="30" class="mr-10 mt-1"/>
                        </div>
                    </v-col>

                    <v-col class="text-right">
                        <v-btn type="submit" @click="send" :disabled="!control.valid || control.processing"
                               color="primary"
                               class="credentials-button py-5"
                               :data-cy="'recover-credentials-send-btn'">
                            <v-icon size="25">mdi-check</v-icon>
                            <span class="ml-3">{{ $t('common.continueBtn') }}</span>
                        </v-btn>
                    </v-col>
                </v-row>

            </v-col>
        </v-fade-transition>


        <v-fade-transition :hide-on-leave="true">
            <div v-show="errors.messages.length" class="error--text font-weight-bold my-1 mx-auto text-center error-msg">
                <div v-for="error in errors.messages" :key="error">
                    {{ $t(`apiErrors.${error}`) }}
                </div>
            </div>
        </v-fade-transition>
    </div>

</template>

<script>
    import ValidationRulesUtils from "@/utilities/validation.rules";
    import BackendService from "@/services/backend.service";
    import ErrorHandler from "@/utilities/error.handler";

    export default {
        name: "ProvideEmail",
        data: () => ({
            control: {
                valid: false,
                processing: false
            },
            form: {
                email: null
            },
            errors: {
                messages: []
            }
        }),
        computed: {
            rules() {
                return ValidationRulesUtils;
            }
        },
        methods: {
            async send() {
                this.control.processing = true;
                this.errors.messages = [];
                const token = crypto.randomUUID().replaceAll('-', '');
                try {
                    await BackendService.recoveryProvideEmail(
                        this.form.email,
                        this.$i18n.locale,
                        token
                    )
                    this.errors.messages = [];
                    this.$emit('change', {key: 'next', token: token});
                } catch (error) {
                    this.errors.messages = ErrorHandler.extractErrorTypes(error);
                } finally {
                    this.control.processing = false;
                }
            }
        },
        mounted() {
            this.$emit('mounted', this);
        }
    }
</script>
