<template>
    <div class="step-content">

        <div class="body-1 font-weight-bold mb-10">
            {{ $t('activateAccount.activationConfirm.activatedLbl') }}
        </div>

        <p>
            {{ $t('activateAccount.activationConfirm.titleLbl') }}
        </p>

        <p class="mb-5">
            {{ $t('activateAccount.activationConfirm.confirmEmailLbl', {value: userEmail}) }} {{ $t('activateAccount.activationConfirm.linkLbl') }}
        </p>

        <p>
            {{ $t('activateAccount.activationConfirm.thankYouLbl') }}
        </p>

        <v-divider class="my-5"></v-divider>

        <v-row>
            <v-col cols="12" class="text-center">
                <v-btn @click="$router.push('/')" color="primary">
                    <v-icon>mdi-check</v-icon>
                    <span class="ml-1">{{ $t('common.returnBtn') }}</span>
                </v-btn>
            </v-col>
        </v-row>

    </div>
</template>


<script>
    export default {
        name: "ActivateAccountActivationConfirmation",
        data: () => ({
            userEmail: null
        }),
        methods: {
            init(userEmail) {
                this.userEmail = userEmail;
            }
        }
    }
</script>