<template>
    <div>

        <div v-if="!control.passwordChanged">
            <v-form v-model="control.valid">
                <v-row>

                    <v-col cols="12" class="mt-5">
                        <div class="body-1 font-weight-bold">{{ $t('recoveryCredentials.passwordView.headerLbl') }}</div>
                    </v-col>

                    <v-col cols="6">
                        <v-text-field
                            :data-cy="'account-confirmation-field-password1'"
                            v-model="form.security.password1"
                            @input="checkPasswords"
                            :rules="rules.required.concat(rules.password)"
                            type="password"
                            autocomplete="new-password"
                            :label="$t('activateAccount.confirmData.form.password')"
                            dense>

                            <template v-slot:append>
                                <v-icon tabindex="-1" v-show="!!form.security.password1"
                                        @click="form.security.password1 = null">mdi-close
                                </v-icon>
                            </template>

                        </v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-text-field
                            :data-cy="'account-confirmation-field-password2'"
                            v-model="form.security.password2"
                            @input="checkPasswords"
                            :rules="rules.required.concat(rules.password)"
                            type="password"
                            autocomplete="new-password"
                            :label="$t('activateAccount.confirmData.form.passwordConfirm')"
                            dense>

                            <template v-slot:append>
                                <v-icon tabindex="-1" v-show="!!form.security.password2"
                                        @click="form.security.password2 = null">mdi-close
                                </v-icon>
                            </template>

                        </v-text-field>
                    </v-col>

                    <v-col cols="12" class="text-center error--text">
                        <v-fade-transition :hide-on-leave="true">
                            <div v-show="errors.password || errors.messages.length" class="error--text font-weight-bold my-1 mx-auto text-center error-msg">
                                <div>
                                    {{ errors.password }}
                                </div>

                                <div v-for="error in errors.messages" :key="error">
                                    {{ $t(`apiErrors.${error}`) }}
                                </div>
                            </div>
                        </v-fade-transition>
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col class="text-left">
                                <v-btn @click="onBack"
                                       outlined class="credentials-button py-5"
                                       :data-cy="'recover-credentials-link-valid-back-btn'">
                                    <v-icon color="primary" size="25">mdi-arrow-left-bold-outline</v-icon>
                                    <span class="ml-1">{{ $t('common.backBtn') }}</span>
                                </v-btn>
                            </v-col>

                            <v-col class="text-center">
                                <div v-show="loading">
                                    <v-progress-circular indeterminate color="primary" :width="3" :size="30" class="mr-10 mt-1"/>
                                </div>
                            </v-col>

                            <v-col class="text-right">
                                <v-btn @click="send" :disabled="!control.valid || errors.password !== null"
                                       color="primary" class="credentials-button py-5"
                                       :data-cy="'recover-credentials-link-valid-send-btn'">
                                    <v-icon size="25">mdi-check</v-icon>
                                    <span class="ml-1">{{ $t('recoveryCredentials.passwordView.sendRequestBtn') }}</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                </v-row>

            </v-form>
        </div>

        <div v-else>
            <div class="mb-10 text-center">
                <div class="title primary--text">
                    {{ $t('recoveryCredentials.passwordView.successPasswordLbl') }}
                    <br/>
                    {{ $t('recoveryCredentials.passwordView.redirectLbl') }}
                </div>
                <v-btn @click="redirectToPBPage" primary class="py-5 mt-5" :data-cy="'navigation-menu-item-recover-credentials-redirect-btn'">
                    <span class="primary--text">{{ $t('recoveryCredentials.passwordView.redirectBtn') }}</span>
                </v-btn>
            </div>

            <div class="mt-10 text-center">
                <v-btn @click="goToTheMainPage" primary class="py-5" :data-cy="'navigation-menu-item-recover-credentials'">
                    <v-icon color="primary" size="25">mdi-arrow-left-bold-outline</v-icon>
                    <span class="ml-3 primary--text">{{ $t('common.returnBtn') }}</span>
                </v-btn>
            </div>
        </div>

    </div>
</template>

<script>
    import ValidationRulesUtils from "@/utilities/validation.rules";
    import BackendService from "@/services/backend.service";
    import ErrorHandler from "@/utilities/error.handler";

    export default {
        name: "PasswordChange",
        props: {
            token: String,
            validationCode: String
        },
        data: () => ({
            control: {
                valid: false,
                passwordChanged: false,
                timeoutId: null
            },
            form: {
                security: {
                    password1: null,
                    password2: null
                }
            },
            loading: false,
            errors: {
                messages: [],
                password: null
            }
        }),
        computed: {
            rules() {
                return ValidationRulesUtils;
            }
        },
        methods: {
            checkPasswords() {
                if (this.form.security.password1 && this.form.security.password2) {
                    if (this.form.security.password1 !== this.form.security.password2) {
                        this.errors.password = this.$i18n.t('activateAccount.confirmData.check.passw');
                    } else {
                        this.errors.password = null;
                    }
                }
            },

            async send() {
                this.loading = true;
                this.control.processing = true;
                this.errors.messages = [];

                try {
                    await BackendService.recoveryProvidePassword(
                        this.token,
                        this.validationCode,
                        this.form.security.password1
                    );
                    this.control.passwordChanged = true;
                    this.errors.messages = [];
                    this.control.timeoutId = setTimeout(() => {
                        this.redirectToPBPage();
                    }, 5000);
                } catch (error) {
                    this.errors.messages = ErrorHandler.extractErrorTypes(error);
                } finally {
                    this.control.processing = false;
                    this.loading = false;
                }
            },

            redirectToPBPage() {
                clearTimeout(this.control.timeoutId);
                const host = location.host;
                const productionHostName = "myaccess.bunge.com";
                const peopleAndBungeProductionSide = "https://performancemanager5.successfactors.eu/login?company=Bunge&loginMethod=SSO";
                const peopleAndBungeTestSide = "https://performancemanager5.successfactors.eu/login?company=BungeTest&loginMethod=SSO";

                if (host === productionHostName) {
                    window.location.href = peopleAndBungeProductionSide;
                } else {
                    window.location.href = peopleAndBungeTestSide;
                }
            },

            goToTheMainPage() {
                clearTimeout(this.control.timeoutId);
                this.$router.push('/')
            },

            onBack() {
                this.$emit('change', {key: 'back'})
            },
        },
        mounted() {
            this.$emit('mounted', this);
        }
    }
</script>


<style scoped>

</style>
