
import EventBus from './event.bus';
import {HTTP_STATUS_403} from './constants';

export default {

    handleGenericError(error, message) {
        EventBus.$emit('show-error-toast', {
            text: error && error.response && error.response.status === HTTP_STATUS_403 ?
                'Insufficient privileges to perform operation' : message
        });
    },

    extractErrorTypes(error) {
        const result = [];

        if(error.response && error.response.data && error.response.data.errorTypes){
            error.response.data.errorTypes.forEach(e => result.push(e));
            return result;
        }

        result.push('UNKNOWN_ERROR');
        return result;
    }
}