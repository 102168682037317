
export default {
    translations: {
        mainPage: {
            languageLbl: 'Selecteer taal',
            titleLbl: 'Welkom bij Bunge MyAccess!',
            subtitleLbl: 'Wat wil je doen?',
            activateAccountLbl: 'Account activeren',
            accountRecoveryLbl: 'Wachtwoord vergeten...',
            changeEmailLbl: 'E-mail wijzigen'
        },
        activateAccount: {
            titleLbl: 'Account activeren',
            residenceCountry: {
                titleLbl: 'De volgende vragen zijn nodig om je op weg te helpen. Nog een paar klikken!',
                selectCountryLbl: 'In welk land woon je?'
            },
            legalPolicy: {
                titleLbl: 'Wettelijke beleidsovereenkomst',
                confirmLbl: 'Ik heb bovenstaande voorwaarden gelezen, begrepen en ga ermee akkoord'
            },
            provideKey: {
                titleLbl: 'Inloggegevens verstrekken',
                enrolmentKeyLbl: 'Gebruik de inloggegevens zoals je deze van de afdeling IT hebt ontvangen, deel deze gegevens niet met anderen'
            },
            confirmData: {
                titleLbl: 'Nog een paar details en je bent klaar!',
                disclaimerLbl: 'Jouw persoonlijke e-mailadres zal door Bunge uitsluitend worden gebruikt voor self-service toegang tot Bunge applicaties en het resetten van wachtwoorden. Bunge zal deze informatie nooit gebruiken voor andere vormen van communicatie.',
                confirmLbl: 'Ik bevestig dat de bovenstaande accountgegevens correct zijn',
                subscriptionLbl: 'Ik wil graag toegang tot de Bunge GoConnect applicatie om nieuws, nieuwsbrieven, mededelingen over Bunge en mijn werkomgeving te ontvangen',
                form: {
                    firstName: 'Voornaam',
                    lastName: 'Achternaam',
                    hireDate: 'Datum in dienst',
                    personExternalId: 'Bunge medarbejder id',
                    emailAddress: 'E-mail adres',
                    password: 'Wachtwoord',
                    passwordConfirm: 'Bevestig wachtwoord',
                    emailConfirm: 'Bevestig e-mail',
                },
                confirmation: {
                    title: 'Account activering',
                    text: 'Jouw account is nu geactiveerd. Even geduld a.u.b.'
                },
                check: {
                    passw: 'Wachtwoorden komen niet overeen',
                    email: 'E-mails komen niet overeen'
                },
                dialog: {
                    title: 'Bevestig account activering',
                    subtitle: 'Jouw account wordt nu geactiveerd.',
                    firstName: 'Voornaam',
                    lastName: 'Achternaam',
                    hireDate: 'Datum in dienst',
                    personExternalId: 'Bunge medarbejder id',
                    email: 'E-mail',
                    question: 'Weet je zeker dat je door wilt gaan?'
                }
            },
            securityQuestion: {
                titleLbl: 'Stel beveiligingsvraag en antwoord in'
            },
            activationConfirm: {
                activatedLbl: 'Account geactiveerd!',
                titleLbl: 'Geweldig, je bent helemaal klaar!',
                confirmEmailLbl: 'We hebben een e-mail bevestiging met alle informatie die je nodig hebt naar je e-mailadres {value} gestuurd.',
                linkLbl: 'Du finder links til Bunge-applikationer.',
                thankYouLbl: 'Dank je wel!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: 'Voer het e-mailadres in dat je bij de registratie hebt gebruikt',
            },
            validateCode: {
                titleLbl: 'Controleer of er een eenmalige validatiecode naar uw e-mailadres is verzonden. Let op: deze code is slechts 5 minuten geldig',
                contentLbl: 'Voer hieronder dezelfde validatiecode in'
            },
            validView: {
                titleLbl: 'Hvad vil du gerne lave?',
                loginBtn: 'Hent login',
                passwordBtn: 'Nulstille kodeord'
            },
            loginView: {
                contentLbl: 'Dit login er:'
            },
            passwordView: {
                headerLbl: 'Angiv venligst den nye adgangskode',
                sendRequestBtn: 'Bevestig nieuw wachtwoord',
                successPasswordLbl: 'Kontoens adgangskode blev ændret.',
                redirectLbl: 'Om få sekunder bliver du omdirigeret til den eksterne side.',
                redirectBtn: 'Omdirigere'
            }
        },
        common: {
            continueBtn: 'Doorgaan',
            backBtn: 'Terug',
            cancelBtn: 'Annuleren',
            downloadBtn: 'Downloaden',
            returnBtn: 'Terug naar de hoofdpagina',
            confirmBtn: 'Bevestigen',
            closeBtn: 'Sluiten'
        },
        validation: {
            required: 'Dit veld is verplicht',
            email: 'Geef een geldig e-mail adres',
            password: 'Geef een geldig wachtwoord (gebruik 1 speciaal teken: !@#$%^&*+=, 1 hoofdletter, 1 cijfer, 8 tot 25 tekens)'
        },
        apiErrors: {
            UNKNOWN_ERROR: 'Onbekende fout heeft zich voorgedaan',
            INVALID_ENROLLMENT_KEY_ERROR: 'Ongeldige inlognaam ingevoerd',
            ACCOUNT_NOT_FOUND_ERROR: 'Account niet gevonden',
            ACCOUNT_INACTIVE_ERROR: 'Account is inactief',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: 'Account is reeds geactiveerd',
            INVALID_EMAIL_FORMAT_ERROR: 'Ongeldig e-mail adres',
            INVALID_EMAIL_ERROR: 'Vul ander e-mail adres in',
            INVALID_PASSWORD_FORMAT_ERROR: 'Het gekozen wachtwoord voldoet niet aan de eisen (1 speciaal teken: !@#$%^&*+=, 1 hoofdletter, 1 cijfer, 8 tot 25 tekens)',
            INVALID_VALIDATION_CODE: 'Oeps! Dit is een ongeldige code. Probeer het opnieuw',
            VALIDATION_CODE_EXPIRED: 'Sorry! Validatiecode is verlopen. Genereer de code opnieuw',
            INVALID_RECOVERY_TOKEN: 'Oeps! Uw sessie \'Wachtwoord vergeten\' is verlopen. Begin alstublieft opnieuw',
            MAX_ATTEMPTS: 'Oh nee! Je hebt het aantal pogingen overschreden. Begin alstublieft opnieuw'
        },
        versioning: {
            title: 'Logboek versiewijzigingen',
            version: 'Versie',
            description: 'Wijzigingen beschrijving'
        },
        idle: {
            title: 'Sessie verlopen',
            info1: 'Sessie is verlopen door lange periode van inactiviteit.',
            info2: 'Toepassing zal opnieuw laden binnen {value} seconden',
            reload: 'Genindlæs nu'
        }
    }
}
