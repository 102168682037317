<template>
    <div class="step-content">

        <div class="mt-2">
            <v-form>
                <v-radio-group v-model="residence.selected" row v-if="residence.available.length > 1">
                    <v-radio :value="option" :label="option.name" v-for="(option, idx) in residence.available" :key="idx"></v-radio>
                </v-radio-group>

                <component :is="legalInfoContentComponent" v-if="residence.selected"></component>

                <v-row>
                    <v-spacer></v-spacer>

                    <v-col cols="12" sm="7">
                        <v-checkbox v-model="form.confirm"
                                    :data-cy="'legal-policy-confirmation-checkbox'"
                                    :label="$t('activateAccount.legalPolicy.confirmLbl')"
                                    hide-details
                                    class="ma-0">
                        </v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="5" class="text-right">
                        <v-btn @click="download"
                               primary text>
                            <v-icon>mdi-download</v-icon><span class="ml-1">{{ $t('common.downloadBtn') }}</span>
                        </v-btn>
                    </v-col>

                    <v-spacer></v-spacer>
                </v-row>

            </v-form>
        </div>

        <v-divider class="my-5"></v-divider>

        <v-row>
            <v-col cols="12" sm="6" class="text-left">
                <v-btn @click="$router.push('/')" primary outlined>
                    <v-icon>mdi-close</v-icon><span class="ml-1">{{ $t('common.cancelBtn') }}</span>
                </v-btn>
            </v-col>

            <v-col cols="12" sm="6" class="text-right">
                <v-btn @click="back"
                       :disabled="control.processing"
                       color="primary"
                       outlined class="mr-10">
                    <v-icon>mdi-arrow-left</v-icon><span class="ml-1">{{ $t('common.backBtn') }}</span>
                </v-btn>

                <v-btn @click="complete"
                       :disabled="!form.confirm"
                       color="primary"
                       :data-cy="'legal-policy-continue-button'">
                    <v-icon>mdi-check</v-icon><span class="ml-1">{{ $t('common.continueBtn') }}</span>
                </v-btn>
            </v-col>
        </v-row>

    </div>
</template>


<script>

import BackendService from "@/services/backend.service";
import LegalDocumentContentComponent_4bwrIQ from '@/views/activate-account/legal-info/LegalDocumentContent_4bwrIQ';
import LegalDocumentContentComponent_5BAZaE from '@/views/activate-account/legal-info/LegalDocumentContent_5BAZaE';
import LegalDocumentContentComponent_CXrafZ from '@/views/activate-account/legal-info/LegalDocumentContent_CXrafZ';
import LegalDocumentContentComponent_Gqwc5X from '@/views/activate-account/legal-info/LegalDocumentContent_Gqwc5X';
import LegalDocumentContentComponent_hPspqE from '@/views/activate-account/legal-info/LegalDocumentContent_hPspqE';
import LegalDocumentContentComponent_jQRln1 from '@/views/activate-account/legal-info/LegalDocumentContent_jQRln1';
import LegalDocumentContentComponent_NaXp88 from '@/views/activate-account/legal-info/LegalDocumentContent_NaXp88';
import LegalDocumentContentComponent_nsdT2w from '@/views/activate-account/legal-info/LegalDocumentContent_nsdT2w';
import LegalDocumentContentComponent_PWffHQ from '@/views/activate-account/legal-info/LegalDocumentContent_PWffHQ';
import LegalDocumentContentComponent_Q2UfD4 from '@/views/activate-account/legal-info/LegalDocumentContent_Q2UfD4';
import LegalDocumentContentComponent_qnEiAd from '@/views/activate-account/legal-info/LegalDocumentContent_qnEiAd';
import LegalDocumentContentComponent_rczNH6 from '@/views/activate-account/legal-info/LegalDocumentContent_rczNH6';
import LegalDocumentContentComponent_Ti8nYX from '@/views/activate-account/legal-info/LegalDocumentContent_Ti8nYX';
import LegalDocumentContentComponent_w5cHV7 from '@/views/activate-account/legal-info/LegalDocumentContent_w5cHV7';
import LegalDocumentContentComponent_wFP7pu from '@/views/activate-account/legal-info/LegalDocumentContent_wFP7pu';
import LegalDocumentContentComponent_zVSVLW from '@/views/activate-account/legal-info/LegalDocumentContent_zVSVLW';
import LegalDocumentContentComponent_nBgJKe from '@/views/activate-account/legal-info/LegalDocumentContent_nBgJKe';
import LegalDocumentContentComponent_tNgGHe from '@/views/activate-account/legal-info/LegalDocumentContent_tNgGHe';
import LegalDocumentContentComponent_dfF5Gb from '@/views/activate-account/legal-info/LegalDocumentContent_dfF5Gb';

export default {
    name: "ActivateAccountLegalPolicy",
    components: {
        LegalDocumentContentComponent_4bwrIQ,
        LegalDocumentContentComponent_5BAZaE,
        LegalDocumentContentComponent_CXrafZ,
        LegalDocumentContentComponent_Gqwc5X,
        LegalDocumentContentComponent_hPspqE,
        LegalDocumentContentComponent_jQRln1,
        LegalDocumentContentComponent_NaXp88,
        LegalDocumentContentComponent_nsdT2w,
        LegalDocumentContentComponent_PWffHQ,
        LegalDocumentContentComponent_Q2UfD4,
        LegalDocumentContentComponent_qnEiAd,
        LegalDocumentContentComponent_rczNH6,
        LegalDocumentContentComponent_Ti8nYX,
        LegalDocumentContentComponent_w5cHV7,
        LegalDocumentContentComponent_wFP7pu,
        LegalDocumentContentComponent_zVSVLW,
        LegalDocumentContentComponent_nBgJKe,
        LegalDocumentContentComponent_tNgGHe,
        LegalDocumentContentComponent_dfF5Gb
    },
    data: () => ({
        control: {
            processing: false
        },
        residence: {
            country: null,
            selected: null,
            available: []
        },
        form: {
            confirm: false
        }
    }),
    computed: {
        legalInfoContentComponent(){
            return `LegalDocumentContentComponent_${this.residence.selected.value}`;
        }
    },
    methods: {

        init(residence) {
            this.residence.country = residence.country;
            this.residence.available = [];

            if(residence.document.nat) {
                this.residence.available.push(residence.document.nat);
            }
            if(residence.document.eng) {
                this.residence.available.push(residence.document.eng);
            }

            this.residence.selected = this.residence.available[0];
        },

        back(){
            this.form.confirm = false;
            this.$emit('back');
        },

        complete(){
            if (this.form.confirm) {
                this.$emit('complete');
            }
        },

        download(){
            const link = window.document.createElement('a');
            link.href = BackendService.DOWNLOAD_HREF(this.residence.selected.value);
            link.download= `Agreement_${this.residence.country}_${this.residence.selected.name}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
</script>