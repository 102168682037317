export default {
    translations: {
        mainPage: {
            languageLbl: '选择语言',
            titleLbl: '欢迎来到Bunge MyAccess!',
            subtitleLbl: '您想做什么？',
            activateAccountLbl: '激活账户',
            accountRecoveryLbl: '忘记密码...',
            changeEmailLbl: '更改电子邮件'
        },
        activateAccount: {
            titleLbl: '帐户激活',
            residenceCountry: {
                titleLbl: '让我们了解一些关于你的信息，让你开始吧。只需再点击几下!',
                selectCountryLbl: '您居住的国家是哪里？'
            },
            legalPolicy: {
                titleLbl: '法律政策协议',
                confirmLbl: '我确认我已阅读、理解并同意上述条件。'
            },
            provideKey: {
                titleLbl: '提供注册密钥',
                enrolmentKeyLbl: '使用人力资源部门提供给你的唯一注册密码。请不要与其他任何人分享该密码。'
            },
            confirmData: {
                titleLbl: '再有几个细节，你就可以准备好了!',
                disclaimerLbl: '您的个人电子邮件地址将由Bunge使用，仅用于Bunge应用程序的自助访问和密码重置。Bunge绝不会将这些信息用于其它类型的通信。',
                confirmLbl: '我确认上述账户数据是正确的',
                subscriptionLbl: '我想访问Bunge GoConnect应用程序，以接收有关Bunge和我的工作环境的新闻、通讯、通信。',
                form: {
                    firstName: '名',
                    lastName: '姓氏',
                    hireDate: '雇用日期',
                    personExternalId: '邦吉员工 ID',
                    emailAddress: '电子邮件地址',
                    password: '密码',
                    passwordConfirm: '确认密码',
                    emailConfirm: '确认电子邮件',
                },
                confirmation: {
                    title: '帐户激活',
                    text: '您的账户现在已经激活。请等待。'
                },
                check: {
                    passw: '密码不匹配',
                    email: '电子邮件不匹配'
                },
                dialog: {
                    title: '确认账户激活',
                    subtitle: '您的账户现在将被激活。',
                    firstName: '名',
                    lastName: '姓氏',
                    hireDate: '雇用日期',
                    personExternalId: '邦吉员工 ID',
                    email: '电子邮件',
                    question: '你确定你要继续吗？'
                }
            },
            securityQuestion: {
                titleLbl: '设置安全问题和答案'
            },
            activationConfirm: {
                activatedLbl: '帐户已激活!',
                titleLbl: '很好，你都准备好了!',
                confirmEmailLbl: '我们向你的电子邮件地址{value}发送了一封确认电子邮件，其中包含了你需要的所有信息。',
                linkLbl: '您会找到 Bunge 应用程序的链接。',
                thankYouLbl: '谢谢您!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: '请输入你注册时使用的电子邮件地址',
            },
            validateCode: {
                titleLbl: '請檢查是否有一次性驗證碼發送到您的電子郵件地址。請注意：此代碼僅在 5 分鐘內有效',
                contentLbl: '請輸入下面相同的驗證碼'
            },
            validView: {
                titleLbl: '你想干什么？',
                loginBtn: '检索登录',
                passwordBtn: '重设密码'
            },
            loginView: {
                contentLbl: '您的登录名是：'
            },
            passwordView: {
                headerLbl: '请提供新密码',
                sendRequestBtn: '确认新密码',
                successPasswordLbl: '帐户密码已成功更改。',
                redirectLbl: '几秒钟后，您将被重定向到外部页面。',
                redirectBtn: '重定向'
            }
        },
        common: {
            continueBtn: '继续',
            backBtn: '返回首页',
            cancelBtn: '取消',
            downloadBtn: '下载',
            returnBtn: '返回主页面',
            confirmBtn: '确认',
            closeBtn: '关闭'
        },
        validation: {
            required: '此栏为必填项',
            email: '请提供有效的电子邮件地址',
            password: '请提供有效的密码（1个特殊字符：!@#$%^&*+=，1个大写字母，1个数字，8至25个字符。）'
        },
        apiErrors: {
            UNKNOWN_ERROR: '发生了未知的错误',
            INVALID_ENROLLMENT_KEY_ERROR: '提供的注册密码无效',
            ACCOUNT_NOT_FOUND_ERROR: '没有找到账户',
            ACCOUNT_INACTIVE_ERROR: '帐户处于非活动状态',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: '帐户已经激活',
            INVALID_EMAIL_FORMAT_ERROR: '无效的电子邮件格式',
            INVALID_EMAIL_ERROR: '请提供不同的电子邮件地址',
            INVALID_PASSWORD_FORMAT_ERROR: '无效的密码格式（1个特殊字符：!@#$%^&*+=，1个大写字母，1个数字，8至25个字符。）',
            INVALID_VALIDATION_CODE: '哎呀！這是無效代碼。請再試一次',
            VALIDATION_CODE_EXPIRED: '對不起！驗證碼已過期。請重新產生程式碼',
            INVALID_RECOVERY_TOKEN: '哎呀！您的「忘記密碼」會話已過期。請重新開始',
            MAX_ATTEMPTS: '不好了！您超出了嘗試次數。請重新開始'
        },
        versioning: {
            title: '版本变更日志',
            version: '版本',
            description: '更改描述'
        },
        idle: {
            title: '会话过期',
            info1: '由于长时间不活动，会话已过期。',
            info2: '应用程序将在{value}秒后重新加载',
            reload: '立即重新加载'
        }
    }
}
