import Vue from 'vue'
import App from './App.vue'
import router from './plugins/router';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import moment from 'moment'
import IdleVue from "idle-vue";
import EventBus from './utilities/event.bus';

Vue.config.productionTip = false;


Vue.use(IdleVue, {
    eventEmitter: EventBus,
    idleTime: 900000, //15min
    startAtIdle: false
});


new Vue({
    router,
    vuetify,
    i18n,
    render: h => h(App),
    methods: {
        changeLocale(locale) {
            this.$vuetify.lang.current = locale;
            this.$i18n.locale = locale;
            moment.locale(locale);
        },
        getLocale() {
            return this.$i18n.locale;
        }
    },
    mounted() {
        this.$i18n.locale = this.$i18n.fallbackLocale;
    }
}).$mount('#app');


//global filters

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY hh:mm');
    }
});
