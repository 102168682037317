
export default {
    translations: {
        mainPage: {
            languageLbl: 'Sprache auswählen',
            titleLbl: 'Willkommen bei Bunge MyAccess!',
            subtitleLbl: 'Was würden Sie gerne tun?',
            activateAccountLbl: 'Konto freischalten',
            accountRecoveryLbl: 'Passwort vergessen...',
            changeEmailLbl: 'E-Mail ändern'
        },
        activateAccount: {
            titleLbl: 'Aktivierung des Kontos',
            residenceCountry: {
                titleLbl: 'Lassen Sie uns ein paar Informationen über Sie sammeln, damit Sie loslegen können. Nur noch ein paar Klicks!',
                selectCountryLbl: 'In welchem Land haben Sie Ihren Wohnsitz?'
            },
            legalPolicy: {
                titleLbl: 'Rechtspolitische Vereinbarung',
                confirmLbl: 'Ich bestätige, dass ich die oben genannten Bedingungen gelesen und verstanden habe und mit ihnen einverstanden bin.'
            },
            provideKey: {
                titleLbl: 'Anmeldeschlüssel bereitstellen',
                enrolmentKeyLbl: 'Verwenden Sie den eindeutigen Anmeldeschlüssel, den Sie von Ihrer Personalabteilung erhalten haben. Bitte geben Sie den Schlüssel nicht an andere Personen weiter.'
            },
            confirmData: {
                titleLbl: 'Noch ein paar Details und Sie sind startklar!',
                disclaimerLbl: 'Ihre persönliche E-Mail-Adresse wird von Bunge ausschließlich für den Selbstbedienungszugang zu Bunge-Anwendungen und zum Zurücksetzen des Passworts verwendet. Bunge wird diese Informationen niemals für andere Arten der Kommunikation verwenden.',
                confirmLbl: 'Ich bestätige, dass die oben genannten Kontodaten korrekt sind.',
                subscriptionLbl: 'Ich möchte auf die Anwendung Bunge GoConnect zugreifen, um Nachrichten, Newsletter und Mitteilungen über Bunge und mein Arbeitsumfeld zu erhalten.',
                form: {
                    firstName: 'Vornamen',
                    lastName: 'Nachname',
                    hireDate: 'Einstellungsdatum',
                    personExternalId: 'Bunge Mitarbeiter ID',
                    emailAddress: 'E-Mail Adresse',
                    password: 'Passwort',
                    passwordConfirm: 'Bestätigen Sie Ihr Passwort',
                    emailConfirm: 'Bestätigen Sie die E-Mail',
                },
                confirmation: {
                    title: 'Kontoaktivierung',
                    text: 'Ihr Konto ist jetzt aktiviert. Bitte warten Sie.'
                },
                check: {
                    passw: 'Passwörter stimmen nicht überein',
                    email: 'Emails stimmen nicht überein'
                },
                dialog: {
                    title: 'Bestätigen Sie die Kontoaktivierung',
                    subtitle: 'Ihr Konto wird nun aktiviert.',
                    firstName: 'Vornamen',
                    lastName: 'Nachname',
                    hireDate: 'Einstellungsdatum',
                    personExternalId: 'Bunge Mitarbeiter ID',
                    email: 'E-Mail',
                    question: 'Sind Sie sicher, dass Sie fortfahren wollen?'
                }
            },
            securityQuestion: {
                titleLbl: 'Sicherheitsfrage und -antwort festlegen'
            },
            activationConfirm: {
                activatedLbl: 'Konto aktiviert!',
                titleLbl: 'Großartig, Sie sind bereit!',
                confirmEmailLbl: 'Wir haben eine E-Mail-Bestätigung mit allen erforderlichen Informationen an Ihre E-Mail-Adresse {value} geschickt.',
                linkLbl: 'Sie finden Links zu Bunge-Anwendungen.',
                thankYouLbl: 'Vielen Dank!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: 'Bitte geben Sie die E-Mail-Adresse ein, mit der Sie sich registriert haben.',
            },
            validateCode: {
                titleLbl: 'Bitte überprüfen Sie, ob ein einmaliger Validierungscode an Ihre E-Mail-Adresse gesendet wurde. Bitte beachten Sie: Dieser Code ist nur 5 Minuten lang gültig',
                contentLbl: 'Bitte geben Sie unten den gleichen Validierungscode ein'
            },
            validView: {
                titleLbl: 'Was würdest du gern tun?',
                loginBtn: 'Login wiederherstellen',
                passwordBtn: 'Passwort zurücksetzen'
            },
            loginView: {
                contentLbl: 'Ihr Login lautet:'
            },
            passwordView: {
                headerLbl: 'Bitte geben Sie das neue Passwort ein',
                sendRequestBtn: 'Bestätigen Sie das neue Passwort',
                successPasswordLbl: 'Das Kontokennwort wurde erfolgreich geändert.',
                redirectLbl: 'In wenigen Sekunden werden Sie auf die externe Seite weitergeleitet.',
                redirectBtn: 'Umleiten'
            }
        },
        common: {
            continueBtn: 'Weiter',
            backBtn: 'Zurück',
            cancelBtn: 'Abbrechen',
            downloadBtn: 'Herunterladen',
            returnBtn: 'Zurück zur Hauptseite',
            confirmBtn: 'Bitte bestätigen',
            closeBtn: 'Bitte schließen'
        },
        validation: {
            required: 'Dieses Feld ist erforderlich',
            email: 'Bitte gültige E-Mail Adresse angeben',
            password: 'Bitte geben Sie ein gültiges Passwort ein (1 Sonderzeichen: !@#$%^&*+=, 1 Großbuchstabe, 1 Ziffer, 8 bis 25 Zeichen)'
        },
        apiErrors: {
            UNKNOWN_ERROR: 'Ein unbekannter Fehler ist aufgetreten',
            INVALID_ENROLLMENT_KEY_ERROR: 'Ungültiger Anmeldeschlüssel angegeben',
            ACCOUNT_NOT_FOUND_ERROR: 'Konto nicht gefunden',
            ACCOUNT_INACTIVE_ERROR: 'Konto ist inaktiv',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: 'Konto ist bereits aktiviert',
            INVALID_EMAIL_FORMAT_ERROR: 'Ungültiges E-Mail-Format',
            INVALID_EMAIL_ERROR: 'Bitte geben Sie eine andere E-Mail Adresse an',
            INVALID_PASSWORD_FORMAT_ERROR: 'Ungültiges Passwortformat (1 Sonderzeichen: !@#$%^&*+=, 1 Großbuchstabe, 1 Ziffer, 8 bis 25 Zeichen)',
            INVALID_VALIDATION_CODE: 'Hoppla! Dies ist ein ungültiger Code. Bitte versuche es erneut',
            VALIDATION_CODE_EXPIRED: 'Entschuldigung! Der Validierungscode ist abgelaufen. Bitte generieren Sie den Code neu',
            INVALID_RECOVERY_TOKEN: 'Hoppla! Ihre „Passwort vergessen“-Sitzung ist abgelaufen. Bitte fangen Sie noch einmal von vorne an',
            MAX_ATTEMPTS: 'Ach nein! Sie haben die Anzahl der Versuche überschritten. Bitte fangen Sie noch einmal von vorne an'
        },
        versioning: {
            title: 'Protokoll der Versionsänderungen',
            version: 'Version',
            description: 'Änderungen Beschreibung'
        },
        idle: {
            title: 'Sitzung abgelaufen',
            info1: 'Die Sitzung ist wegen längerer Inaktivität abgelaufen.',
            info2: 'Anwendung wird nach {value} Sekunden neu geladen',
            reload: 'Jetzt neu laden'
        }
    }
}
