import { render, staticRenderFns } from "./LegalDocumentContent_dfF5Gb.vue?vue&type=template&id=30134b56&scoped=true&"
var script = {}
import style0 from "./LegalDocumentContent_dfF5Gb.vue?vue&type=style&index=0&id=30134b56&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30134b56",
  null
  
)

export default component.exports