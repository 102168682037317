
export default {
    translations: {
        mainPage: {
            languageLbl: 'Choisir la langue',
            titleLbl: 'Bienvenue sur Bunge MyAccess!',
            subtitleLbl: 'Qu\'est-ce que tu aimerais faire?',
            activateAccountLbl: 'Activer le compte',
            accountRecoveryLbl: 'Mot de passe oublié...',
            changeEmailLbl: 'Changer l\'e-mail'
        },
        activateAccount: {
            titleLbl: 'Activation de compte',
            residenceCountry: {
                titleLbl: 'Récupérons quelques informations sur vous pour configurer votre compte. Ne vous inquiétez pas, vous serez prêt dans quelques minutes !',
                selectCountryLbl: 'Quel est votre pays de résidence?'
            },
            legalPolicy: {
                titleLbl: 'Accord de politique juridique',
                confirmLbl: 'Je confirme avoir lu, compris et accepté les conditions ci-dessus.'
            },
            provideKey: {
                titleLbl: 'Fournir la clé d\'inscription',
                enrolmentKeyLbl: 'Utilisez votre clé d\'inscription unique qui vous a été fournie par vos ressources humaines. Ceci est une information privée, ne la partagez avec personne d\'autre.'
            },
            confirmData: {
                titleLbl: 'Need a few more details and you\'re all set!',
                disclaimerLbl: 'Votre adresse e-mail personnelle sera utilisée par Bunge dans le seul but d\'accéder en libre-service aux applications Bunge et de réinitialiser le mot de passe. Bunge n\'utilisera jamais ces informations pour tout autre type de communication.',
                confirmLbl: 'Je confirme que les données de compte ci-dessus sont correctes',
                subscriptionLbl: 'Je souhaite accéder à l\'application Bunge GoConnect pour recevoir des actualités, des newsletters, des communications sur Bunge et mon environnement de travail',
                form: {
                    firstName: 'Prénom',
                    lastName: 'Nom de famille',
                    hireDate: 'Date d\'embauche',
                    personExternalId: "ID d'employé Bunge",
                    emailAddress: 'Adresse e-mail',
                    password: 'Mot de passe',
                    passwordConfirm: 'Confirmez le mot de passe',
                    emailConfirm: 'Confirmez votre e-mail',
                },
                confirmation: {
                    title: 'Activation de compte',
                    text: 'Votre compte est maintenant activé. S\'il vous plaît, attendez.'
                },
                check: {
                    passw: 'Les mots de passe ne correspondent pas',
                    email: 'Les e-mails ne correspondent pas'
                },
                dialog: {
                    title: 'Confirmer l\'activation du compte',
                    subtitle: 'Votre compte va maintenant être activé.',
                    firstName: 'Prénom',
                    lastName: 'Nom de famille',
                    hireDate: 'Date d\'embauche',
                    personExternalId: "ID d'employé Bunge",
                    email: 'E-mail',
                    question: 'Êtes-vous sur de vouloir continuer?'
                }
            },
            securityQuestion: {
                titleLbl: 'Définir la question et la réponse de sécurité',
            },
            activationConfirm: {
                activatedLbl: 'Compte activé !',
                titleLbl: 'Super, vous êtes prêt!',
                confirmEmailLbl: 'Nous avons envoyé un e-mail de confirmation à votre adresse e-mail',
                linkLbl: 'Vous trouverez des liens vers des applications Bunge.',
                thankYouLbl: 'Merci!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: 'Veuillez saisir l\'adresse e-mail que vous avez utilisée lors de votre inscription',
            },
            validateCode: {
                titleLbl: 'Veuillez vérifier s\'il y a un code de validation unique envoyé à votre adresse e-mail. Attention : ce code n\'est valable que 5 minutes',
                contentLbl: 'Veuillez saisir le même code de validation ci-dessous'
            },
            validView: {
                titleLbl: 'Qu\'est-ce que tu aimerais faire?',
                loginBtn: 'Récupérer la connexion',
                passwordBtn: 'Réinitialiser le mot de passe'
            },
            loginView: {
                contentLbl: 'Votre identifiant est:'
            },
            passwordView: {
                headerLbl: 'Veuillez fournir le nouveau mot de passe',
                sendRequestBtn: 'Confirmer le nouveau mot de passe',
                successPasswordLbl: 'Le mot de passe du compte a été modifié avec succès.',
                redirectLbl: 'Dans quelques secondes, vous serez redirigé vers la page externe.',
                redirectBtn: 'Réorienter'
            }
        },
        common: {
            continueBtn: 'Continuer',
            backBtn: 'Dos',
            cancelBtn: 'Annuler',
            downloadBtn: 'Télécharger',
            returnBtn: 'Revenir à la page principale',
            confirmBtn: 'Confirmer',
            closeBtn: 'Proche'
        },
        validation: {
            required: 'Ce champ est requis',
            email: 'Veuillez fournir une adresse e-mail valide',
            password: 'Veuillez fournir un mot de passe valide (1 caractère spécial: !@#$%^&*+=, 1 lettre majuscule, 1 chiffre, 8 à 25 caractères)'
        },
        apiErrors: {
            UNKNOWN_ERROR: 'Une erreur inconnue s\'est produite',
            INVALID_ENROLLMENT_KEY_ERROR: 'Clé d\'inscription non valide fournie',
            ACCOUNT_NOT_FOUND_ERROR: 'Compte non trouvé',
            ACCOUNT_INACTIVE_ERROR: 'Le compte est inactif',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: 'Compte déjà activé',
            INVALID_EMAIL_FORMAT_ERROR: 'Format d\'email invalide',
            INVALID_EMAIL_ERROR: 'Veuillez fournir une adresse e-mail différente',
            INVALID_PASSWORD_FORMAT_ERROR: 'Format de mot de passe non valide (1 caractère spécial: !@#$%^&*+=, 1 lettre majuscule, 1 chiffre, 8 à 25 caractères)',
            INVALID_VALIDATION_CODE: 'Oops! Ceci est un code invalide. Veuillez réessayer',
            VALIDATION_CODE_EXPIRED: 'Désolé! Le code de validation a expiré. Veuillez régénérer le code',
            INVALID_RECOVERY_TOKEN: 'Oops! Votre session « Mot de passe oublié » a expiré. Veuillez recommencer',
            MAX_ATTEMPTS: 'Oh non! Vous avez dépassé le nombre de tentatives. Veuillez recommencer'
        },
        versioning: {
            title: 'Journal des changements de version',
            version: 'Version',
            description: 'Description des modifications',
        },
        idle: {
            title: 'La session a expiré',
            info1: 'La session a expiré en raison d\'une longue période d\'inactivité.',
            info2: 'L\'application se rechargera après {value} secondes',
            reload: 'Recharger maintenant'
        }
    }
}
