<template>
    <v-dialog v-model="control.visible" max-width="600" persistent>
        <v-card>

            <v-card-title>
                <div class="headline">
                    <span class="primary--text">{{options.title}}</span>
                </div>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="mt-10 pl-7 subtitle-1">
                <v-progress-circular indeterminate size="50" color="primary">
                    <!---->
                </v-progress-circular>
                <span class="ml-5">
                    {{options.text}}
                </span>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>


<script>
    export default {
        data: () => ({
            control: {
                visible: false
            },
            options: {}
        }),
        methods: {
            show(options) {
                this.options = options || {};
                this.control.visible = true;
            },

            hide() {
                this.control.visible = false;
            }
        }
    }
</script>