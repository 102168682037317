
export default {
    translations: {
        mainPage: {
            languageLbl: 'Dil Seçimi',
            titleLbl: 'Bunge MyAccess\'ye hoş geldiniz!',
            subtitleLbl: 'Ne yapmak istersiniz?',
            activateAccountLbl: 'Hesabınızı aktive edin',
            accountRecoveryLbl: 'Parolanızı mı unuttunuz...',
            changeEmailLbl: 'E-posta değiştir'
        },
        activateAccount: {
            titleLbl: 'Hesap Aktivasyonu',
            residenceCountry: {
                titleLbl: 'Başlamadan önce sizinle ilgili biraz bilgi alalım. Sadece birkaç tıklama daha yapın!',
                selectCountryLbl: 'İkamet ettiğiniz ülke neresidir?'
            },
            legalPolicy: {
                titleLbl: 'Yasal politika anlaşması',
                confirmLbl: 'Yukarıdaki koşulları okuduğumu, anladığımı ve kabul ettiğimi onaylıyorum'
            },
            provideKey: {
                titleLbl: 'Kayıt şifresi sağlayın',
                enrolmentKeyLbl: 'İnsan Kaynakları tarafından size sağlanan ve benzeri olmayan kayıt şifresini kullanın. Lütfen şifre detaylarını kimseyle paylaşmayın.'
            },
            confirmData: {
                titleLbl: 'Birkaç ayrıntı daha ve her şey hazır!',
                disclaimerLbl: 'Kişisel e-posta adresiniz Bunge tarafından yalnızca Bunge uygulamalarındaki self servis erişimi ve parola sıfırlamak amacıyla kullanılacaktır. Bunge, bu bilgileri asla başka bir iletişim türünde kullanmayacaktır.',
                confirmLbl: 'Yukarıdaki hesap verilerinin doğru olduğunu onaylıyorum',
                subscriptionLbl: 'Bunge ve çalışma ortamım hakkında haberler, bültenler, bilgiler almak için Bunge GoConnect uygulamasına erişmek istiyorum',
                form: {
                    firstName: 'İsim',
                    lastName: 'Soyisim',
                    hireDate: 'İşe Başlangıç Tarihi',
                    personExternalId: 'Bunge Çalışan Kimliği',
                    emailAddress: 'E-posta adresi',
                    password: 'Şifre',
                    passwordConfirm: 'Şifreyi Onayla',
                    emailConfirm: 'E-postanızı Onaylayın',
                },
                confirmation: {
                    title: 'Hesap Aktivasyonu',
                    text: 'Hesabınız şimdi etkinleştirildi. Lütfen bekleyin.'
                },
                check: {
                    passw: 'Parolalar eşleşmiyor',
                    email: 'E-postalar eşleşmiyor'
                },
                dialog: {
                    title: 'Hesap aktivasyonunu onaylayın',
                    subtitle: 'Hesabınız şimdi etkinleştirilecektir.',
                    firstName: 'İsim',
                    lastName: 'Soyisim',
                    hireDate: 'İşe Başlangıç Tarihi',
                    personExternalId: 'Bunge Çalışan Kimliği',
                    email: 'E-posta adresi',
                    question: 'Devam etmek istediğinizden emin misiniz?'
                }
            },
            securityQuestion: {
                titleLbl: 'Güvenlik sorusunu ve yanıtını belirle'
            },
            activationConfirm: {
                activatedLbl: 'Hesap etkinleştirildi!',
                titleLbl: 'Harika, hazırsın!',
                confirmEmailLbl: '{value} e-posta adresinize ihtiyacınız olan tüm bilgileri içeren bir e-posta onayı gönderdik.',
                linkLbl: 'Bunge uygulamalarına bağlantılar bulacaksınız.',
                thankYouLbl: 'Teşekkür ederiz!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: 'Lütfen kayıt olurken kullandığınız e-posta adresinizi giriniz',
            },
            validateCode: {
                titleLbl: 'Lütfen e-posta adresinize tek seferlik doğrulama kodunun gönderilip gönderilmediğini kontrol edin. Lütfen dikkat: Bu kod yalnızca 5 dakika geçerlidir.',
                contentLbl: 'Lütfen aynı doğrulama kodunu aşağıya girin'
            },
            validView: {
                titleLbl: 'Ne yapmak istersin?',
                loginBtn: 'Girişi al',
                passwordBtn: 'Şifreyi yenile'
            },
            loginView: {
                contentLbl: 'Girişiniz:'
            },
            passwordView: {
                headerLbl: 'Lütfen yeni şifreyi girin',
                sendRequestBtn: 'Yeni şifreyi onayla',
                successPasswordLbl: 'Hesap şifresi başarıyla değiştirildi.',
                redirectLbl: 'Birkaç saniye içinde harici sayfaya yönlendirileceksiniz.',
                redirectBtn: 'Yönlendirme'
            }
        },
        common: {
            continueBtn: 'Devam et',
            backBtn: 'Geri',
            cancelBtn: 'İptal et',
            downloadBtn: 'İndir',
            returnBtn: 'Ana sayfaya dön',
            confirmBtn: 'Onayla',
            closeBtn: 'Kapat'
        },
        validation: {
            required: 'Bu alan gereklidir',
            email: 'Lütfen geçerli bir e-posta adresi girin',
            password: 'Lütfen geçerli bir şifre girin (1 özel karakter: !@#$%^&*+=, 1 büyük harf, 1 rakam, 8 ila 25 karakter)'
        },
        apiErrors: {
            UNKNOWN_ERROR: 'Bilinmeyen bir hata oluştu',
            INVALID_ENROLLMENT_KEY_ERROR: 'Geçersiz kayıt şifresi sağlandı',
            ACCOUNT_NOT_FOUND_ERROR: 'Hesap bulunamadı',
            ACCOUNT_INACTIVE_ERROR: 'Hesap etkin değil',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: 'Hesap daha önce etkinleştirildi',
            INVALID_EMAIL_FORMAT_ERROR: 'Geçersiz e-posta formatı',
            INVALID_EMAIL_ERROR: 'Lütfen farklı bir e-posta adresi girin',
            INVALID_PASSWORD_FORMAT_ERROR: 'Geçersiz şifre formatı (1 özel karakter: !@#$%^&*+=, 1 büyük harf, 1 rakam, 8 ila 25 karaktere kadar)',
            INVALID_VALIDATION_CODE: 'Hata! Bu geçersiz bir koddur. Lütfen tekrar deneyin',
            VALIDATION_CODE_EXPIRED: 'Üzgünüm! Doğrulama kodunun süresi doldu. Lütfen kodu yeniden oluşturun',
            INVALID_RECOVERY_TOKEN: 'Hata! \'Şifremi Unuttum\' oturumunuzun süresi doldu. Lütfen tekrar başlayın',
            MAX_ATTEMPTS: 'Oh hayır! Deneme sayısını aştınız. Lütfen tekrar başlayın'
        },
        versioning: {
            title: 'Sürüm değişikliği kaydı',
            version: 'sürüm',
            description: 'Açıklamayı değiştirir'
        },
        idle: {
            title: 'Oturum süresi doldu',
            info1: 'Uzun süre hareketsizlik nedeniyle oturumun süresi doldu.',
            info2: 'Uygulama {value} saniyeler içerisinde yeniden yüklenecek',
            reload: 'Şimdi yeniden yükle'
        }
    }
}
