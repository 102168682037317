
export default {
    translations: {
        mainPage: {
            languageLbl: 'Выберите язык',
            titleLbl: 'Добро пожаловать в Bunge MyAccess!',
            subtitleLbl: 'Что бы вы хотели сделать?',
            activateAccountLbl: 'Активировать учетную запись',
            accountRecoveryLbl: 'Забыли пароль...',
            changeEmailLbl: 'Изменить электронную почту'
        },
        activateAccount: {
            titleLbl: 'Активация аккаунта',
            residenceCountry: {
                titleLbl: 'Давайте узнаем немного информации о вас, чтобы начать работу. Еще несколько кликов!',
                selectCountryLbl: 'В какой стране вы проживаете?'
            },
            legalPolicy: {
                titleLbl: 'Соглашение о правовой политике',
                confirmLbl: 'Я подтверждаю, что прочитал, понял и согласен с вышеуказанными условиями'
            },
            provideKey: {
                titleLbl: 'Предоставить ключ регистрации',
                enrolmentKeyLbl: 'Используйте уникальный ключ регистрации, предоставленный вам отделом кадров. Пожалуйста, не передавайте ключ никому другому.'
            },
            confirmData: {
                titleLbl: 'Еще пара деталей, и все готово!',
                disclaimerLbl: 'Ваш личный адрес электронной почты будет использоваться компанией Bunge исключительно в целях самостоятельного доступа к приложениям Bunge и сброса пароля. Bunge никогда не будет использовать эту информацию для любого другого вида связи.',
                confirmLbl: 'Я подтверждаю, что вышеуказанные данные счета верны',
                subscriptionLbl: 'Я хотел бы получить доступ к приложению Bunge GoConnect для получения новостей, информационных бюллетеней, сообщений о компании Bunge и моей рабочей среде',
                form: {
                    firstName: 'Имя',
                    lastName: 'Фамилия',
                    hireDate: 'Дата приема на работу',
                    personExternalId: 'Идентификатор сотрудника Bunge',
                    emailAddress: 'Адрес электронной почты',
                    password: 'Пароль',
                    passwordConfirm: 'Подтвердите пароль',
                    emailConfirm: 'Подтвердите электронную почту',
                },
                confirmation: {
                    title: 'Активация учетной записи',
                    text: 'Ваша учетная запись сейчас активирована. Пожалуйста, подождите.'
                },
                check: {
                    passw: 'Пароли не совпадают',
                    email: 'Электронная почта не совпадает'
                },
                dialog: {
                    title: 'Подтвердите активацию аккаунта',
                    subtitle: 'Теперь ваш аккаунт будет активирован.',
                    firstName: 'Имя',
                    lastName: 'Фамилия',
                    hireDate: 'Дата приема на работу',
                    personExternalId: 'Идентификатор сотрудника Bunge',
                    email: 'Электронная почта',
                    question: 'Вы уверены, что хотите продолжить?'
                }
            },
            securityQuestion: {
                titleLbl: 'Задайте секретный вопрос и ответ'
            },
            activationConfirm: {
                activatedLbl: 'Аккаунт активирован!',
                titleLbl: 'Отлично, все готово!',
                confirmEmailLbl: 'Мы отправили подтверждение по электронной почте со всей необходимой информацией на ваш электронный адрес {value}.',
                linkLbl: 'Вы найдете ссылки на приложения Bunge.',
                thankYouLbl: 'Спасибо!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: 'Пожалуйста, введите адрес электронной почты, который вы использовали при регистрации',
            },
            validateCode: {
                titleLbl: 'Пожалуйста, проверьте наличие одноразового кода подтверждения, отправленного на ваш адрес электронной почты. Обратите внимание: этот код действителен только в течение 5 минут.',
                contentLbl: 'Пожалуйста, введите тот же код подтверждения ниже'
            },
            validView: {
                titleLbl: 'Чем бы Вы хотели заняться?',
                loginBtn: 'Получить логин',
                passwordBtn: 'Сброс пароля'
            },
            loginView: {
                contentLbl: 'Ваш логин:'
            },
            passwordView: {
                headerLbl: 'Пожалуйста, укажите новый пароль',
                sendRequestBtn: 'Подтвердите новый пароль',
                successPasswordLbl: 'Пароль учетной записи был успешно изменен.',
                redirectLbl: 'Через несколько секунд вы будете перенаправлены на внешнюю страницу.',
                redirectBtn: 'Перенаправить'
            }
        },
        common: {
            continueBtn: 'Продолжить',
            backBtn: 'Назад',
            cancelBtn: 'Отмена',
            downloadBtn: 'Скачать',
            returnBtn: 'Вернуться на главную страницу',
            confirmBtn: 'Подтвердить',
            closeBtn: 'Закрыть'
        },
        validation: {
            required: 'Это поле обязательно для заполнения',
            email: 'Пожалуйста, укажите действительный адрес электронной почты',
            password: 'Пожалуйста, введите правильный пароль (1 специальный символ: !@#$%^&*+=, 1 заглавная буква, 1 цифра, от 8 до 25 символов)'
        },
        apiErrors: {
            UNKNOWN_ERROR: 'Произошла неизвестная ошибка',
            INVALID_ENROLLMENT_KEY_ERROR: 'Предоставлен недействительный ключ регистрации',
            ACCOUNT_NOT_FOUND_ERROR: 'Учетная запись не найдена',
            ACCOUNT_INACTIVE_ERROR: 'Учетная запись неактивна',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: 'Учетная запись уже активирована',
            INVALID_EMAIL_FORMAT_ERROR: 'Неверный формат электронной почты',
            INVALID_EMAIL_ERROR: 'Пожалуйста, укажите другой адрес электронной почты',
            INVALID_PASSWORD_FORMAT_ERROR: 'Неверный формат пароля (1 специальный символ: !@#$%^&*+=, 1 заглавная буква, 1 цифра, от 8 до 25 символов)',
            INVALID_VALIDATION_CODE: 'Упс! Это неверный код. Пожалуйста, попробуйте еще раз',
            VALIDATION_CODE_EXPIRED: 'Извини! Срок действия кода проверки истек. Пожалуйста, перегенерируйте код',
            INVALID_RECOVERY_TOKEN: 'Упс! Срок действия сеанса «Забыли пароль» истек. Пожалуйста, начните снова',
            MAX_ATTEMPTS: 'О, нет! Вы превысили количество попыток. Пожалуйста, начните снова'
        },
        versioning: {
            title: 'Журнал изменений версии',
            version: 'Версия',
            description: 'Описание изменений'
        },
        idle: {
            title: 'Сессия истекла',
            info1: 'Сессия истекла из-за длительного периода бездействия.',
            info2: 'Приложение будет перезагружено через {value} секунд',
            reload: 'Перезагрузить сейчас'
        }
    }
}
