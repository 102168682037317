
export default {
    translations: {
        mainPage: {
            languageLbl: 'Pilih Bahasa',
            titleLbl: 'Selamat datang ke Bunge MyAccess!',
            subtitleLbl: 'Apa yang anda ingin lakukan?',
            activateAccountLbl: 'Aktifkan akaun',
            accountRecoveryLbl: 'Lupa kata laluan...',
            changeEmailLbl: 'Tukar emel'
        },
        activateAccount: {
            titleLbl: 'Pengaktifan Akaun',
            residenceCountry: {
                titleLbl: 'Mari dapatkan sedikit maklumat tentang anda untuk memulakan penyertaan anda. Hanya beberapa klik lagi!',
                selectCountryLbl: 'Apakah negara tempat tinggal anda?'
            },
            legalPolicy: {
                titleLbl: 'Perjanjian dasar undang-undang',
                confirmLbl: 'Saya mengesahkan saya telah membaca, memahami dan bersetuju dengan syarat di atas'
            },
            provideKey: {
                titleLbl: 'Sediakan kunci pendaftaran',
                enrolmentKeyLbl: 'Gunakan kunci pendaftaran unik yang diberikan kepada anda oleh Sumber Manusia anda.Pastikan jangan kongsi kunci dengan orang lain.'
            },
            confirmData: {
                titleLbl: 'Beberapa butiran lagi dan anda sudah bersedia!',
                disclaimerLbl: 'Alamat e-mel peribadi anda akan digunakan oleh Bunge untuk tujuan tunggal akses layan diri kepada aplikasi Bunge dan tetapan semula kata laluan. Bunge tidak akan menggunakan maklumat ini dalam sebarang jenis komunikasi lain.',
                confirmLbl: 'Saya mengesahkan bahawa data akaun di atas adalah betul',
                subscriptionLbl: 'Saya ingin mengakses aplikasi Bunge GoConnect untuk menerima berita, surat berita, komunikasi tentang Bunge dan persekitaran kerja saya',
                form: {
                    firstName: 'Nama pertama',
                    lastName: 'Nama terakhir',
                    hireDate: 'Tarikh mula bekerja',
                    personExternalId: 'ID Pekerja Bunge',
                    emailAddress: 'Alamat emel',
                    password: 'Kata laluan',
                    passwordConfirm: 'Sahkan kata laluan',
                    emailConfirm: 'Sahkan e-mel',
                },
                confirmation: {
                    title: 'Pengaktifan Akaun',
                    text: 'Akaun anda kini diaktifkan. Sila tunggu.'
                },
                check: {
                    passw: 'Kata laluan tidak sepadan',
                    email: 'e-mail tidak sepadan'
                },
                dialog: {
                    title: 'Sahkan pengaktifan akaun',
                    subtitle: 'Akaun anda kini akan diaktifkan.',
                    firstName: 'Nama pertama',
                    lastName: 'Nama terakhir',
                    hireDate: 'Tarikh mula bekerja',
                    personExternalId: 'ID Pekerja Bunge',
                    email: 'E-mel',
                    question: 'Adakah anda pasti mahu meneruskan?'
                }
            },
            securityQuestion: {
                titleLbl: 'Tetapkan soalan dan jawapan keselamatan'
            },
            activationConfirm: {
                activatedLbl: 'Akaun diaktifkan!',
                titleLbl: 'Hebat, anda sudah bersedia!',
                confirmEmailLbl: 'Kami menghantar pengesahan e-mel dengan semua maklumat yang anda perlukan ke alamat e-mel anda {value}.',
                linkLbl: 'Anda akan menemui pautan ke aplikasi Bunge.',
                thankYouLbl: 'Terima kasih!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: 'Sila masukkan alamat e-mel yang anda gunakan semasa mendaftar',
            },
            validateCode: {
                titleLbl: 'Sila semak kod pengesahan sekali dihantar ke alamat e-mel anda. Sila ambil perhatian: Kod ini hanya sah selama 5 minit',
                contentLbl: 'Sila masukkan kod pengesahan yang sama di bawah'
            },
            validView: {
                titleLbl: 'Apa yang anda ingin lakukan?',
                loginBtn: 'Dapatkan semula log masuk',
                passwordBtn: 'Menetapkan semula kata laluan'
            },
            loginView: {
                contentLbl: 'Log masuk anda ialah:'
            },
            passwordView: {
                headerLbl: 'Sila berikan kata laluan baharu',
                sendRequestBtn: 'Sahkan kata laluan baharu',
                successPasswordLbl: 'Kata laluan akaun telah berjaya ditukar.',
                redirectLbl: 'Dalam beberapa saat anda akan dialihkan ke halaman luaran.',
                redirectBtn: 'Ubah hala'
            }
        },
        common: {
            continueBtn: 'teruskan',
            backBtn: 'belakang',
            cancelBtn: 'Batal',
            downloadBtn: 'Muat turun',
            returnBtn: 'Kembali ke halaman utama',
            confirmBtn: 'sahkan',
            closeBtn: 'tutup'
        },
        validation: {
            required: 'Ruangan ini diperlukan',
            email: 'Sila berikan alamat e-mel yang sah',
            password: 'Sila berikan kata laluan yang sah (1 aksara khas: !@#$%^&*+=, 1 huruf besar, 1 digit, 8 hingga 25 aksara)'
        },
        apiErrors: {
            UNKNOWN_ERROR: 'Ralat tidak diketahui telah berlaku',
            INVALID_ENROLLMENT_KEY_ERROR: 'Kunci pendaftaran tidak sah diberikan',
            ACCOUNT_NOT_FOUND_ERROR: 'akaun tidak dijumpai',
            ACCOUNT_INACTIVE_ERROR: 'Akaun tidak aktif',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: 'Akaun sudah diaktifkan',
            INVALID_EMAIL_FORMAT_ERROR: 'Format e-mel tidak sah',
            INVALID_EMAIL_ERROR: 'Sila berikan alamat e-mel yang berbeza',
            INVALID_PASSWORD_FORMAT_ERROR: 'Format kata laluan tidak sah (1 aksara khas: !@#$%^&*+=, 1 huruf besar, 1 digit, 8 hingga 25 aksara)',
            INVALID_VALIDATION_CODE: 'Aduh! Ini adalah kod yang tidak sah. Sila cuba lagi',
            VALIDATION_CODE_EXPIRED: 'Maaf! Kod pengesahan telah tamat tempoh. Sila jana semula kod',
            INVALID_RECOVERY_TOKEN: 'Aduh! Sesi \'Lupa Kata Laluan\' anda telah tamat tempoh. Sila mulakan semula',
            MAX_ATTEMPTS: 'Oh tidak! Anda melebihi bilangan percubaan. Sila mulakan semula'
        },
        versioning: {
            title: 'Log perubahan versi',
            version: 'Versi',
            description: 'Mengubah deskripsi'
        },
        idle: {
            title: 'Sesi Tamat',
            info1: 'Sesi telah tamat tempoh kerana jangkamasa tidak aktif yang lama.',
            info2: 'Aplikasi akan dimuat semula selepas {value} saat',
            reload: 'Muat semula sekarang'
        }
    }
}
