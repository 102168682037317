
export default {
    translations: {
        mainPage: {
            languageLbl: 'Select language',
            titleLbl: 'Welcome to Bunge MyAccess!',
            subtitleLbl: 'What would you like to do?',
            activateAccountLbl: 'Activate account',
            accountRecoveryLbl: 'Forgot Password...',
            changeEmailLbl: 'Change email'
        },
        activateAccount: {
            titleLbl: 'Account activation',
            residenceCountry: {
                titleLbl: 'Let’s get a little information about you to get you started. Just a few more clicks!',
                selectCountryLbl: 'What is your country of residence?'
            },
            legalPolicy: {
                titleLbl: 'Legal policy agreement',
                confirmLbl: 'I confirm I have read, understood and agreed with the conditions above'
            },
            provideKey: {
                titleLbl: 'Provide enrollment key',
                enrolmentKeyLbl: 'Use the unique enrollment key provided to you by your Human Resources. Please do not share the key with anyone else.'
            },
            confirmData: {
                titleLbl: 'A couple of more details and you\'re all set!',
                disclaimerLbl: 'Your personal email address will be used by Bunge for the sole purpose of self service access to Bunge applications and password reset. Bunge will never use this information any other type of communication.',
                confirmLbl: 'I confirm that the above account data is correct',
                subscriptionLbl: 'I would like to access Bunge GoConnect application to receive news, newsletters, communications about Bunge and my work environment',
                form: {
                    firstName: 'First name',
                    lastName: 'Last name',
                    hireDate: 'Hire date',
                    personExternalId: "Bunge Employee ID",
                    emailAddress: 'Email address',
                    password: 'Password',
                    passwordConfirm: 'Confirm password',
                    emailConfirm: 'Confirm email',
                },
                confirmation: {
                    title: 'Account activation',
                    text: 'Your account is now activated. Please wait.'
                },
                check: {
                    passw: 'Passwords do not match',
                    email: 'Emails do not match'
                },
                dialog: {
                    title: 'Confirm account activation',
                    subtitle: 'Your account will now be activated.',
                    firstName: 'First name',
                    lastName: 'Last name',
                    hireDate: 'Hire date',
                    personExternalId: "Bunge Employee ID",
                    email: 'Email',
                    question: 'Are you sure you want to proceed?'
                }
            },
            securityQuestion: {
                titleLbl: 'Set security question and answer',
            },
            activationConfirm: {
                activatedLbl: 'Account activated!',
                titleLbl: 'Great, you\'re all set!',
                confirmEmailLbl: 'We sent an e-mail confirmation with all the information you need to your email address {value}.',
                linkLbl: 'You will find links to Bunge applications.',
                thankYouLbl: 'Thank You!'
            }
        },
        recoveryCredentials: {
            provideEmail: {
                contentLbl: 'Please enter the email address you used while registering',
            },
            validateCode: {
                titleLbl: 'Please check for a one time validation code sent to your email address. Kindly note: This code is valid only for 5 minutes',
                contentLbl: 'Please enter the same validation code below'
            },
            validView: {
                titleLbl: 'What would you like to do?',
                loginBtn: 'Retrieve login',
                passwordBtn: 'Reset password'
            },
            loginView: {
                contentLbl: 'Your login is:'
            },
            passwordView: {
                headerLbl: 'Please provide the new password',
                sendRequestBtn: 'Confirm new password',
                successPasswordLbl: 'The account password was successfully changed.',
                redirectLbl: 'In a few seconds you will be redirected to the external page.',
                redirectBtn: 'Redirect'
            }
        },
        common: {
            continueBtn: 'Continue',
            backBtn: 'Back',
            cancelBtn: 'Cancel',
            downloadBtn: 'Download',
            returnBtn: 'Return to the main page',
            confirmBtn: 'Confirm',
            closeBtn: 'Close'
        },
        validation: {
            required: 'This field is required',
            email: 'Please provide valid email address',
            password: 'Please provide valid password (1 special character: !@#$%^&*+=, 1 uppercase letter, 1 digit, 8 to 25 characters)'
        },
        apiErrors: {
            UNKNOWN_ERROR: 'Unknown error has occurred',
            INVALID_ENROLLMENT_KEY_ERROR: 'Invalid enrollment key provided',
            ACCOUNT_NOT_FOUND_ERROR: 'Account not found',
            ACCOUNT_INACTIVE_ERROR: 'Account is inactive',
            ACCOUNT_ALREADY_ACTIVATED_ERROR: 'Account already activated',
            INVALID_EMAIL_FORMAT_ERROR: 'Invalid email format',
            INVALID_EMAIL_ERROR: 'Please provide different email address',
            INVALID_PASSWORD_FORMAT_ERROR: 'Invalid password format (1 special character: !@#$%^&*+=, 1 uppercase letter, 1 digit, 8 to 25 characters)',
            INVALID_VALIDATION_CODE: 'Oops! This is an invalid code. Please try again',
            VALIDATION_CODE_EXPIRED: 'Sorry! Validation code has expired. Please regenerate the code',
            INVALID_RECOVERY_TOKEN: 'Oops! Your \'Forgot Password\' session has expired. Kindly start again',
            MAX_ATTEMPTS: 'Oh no! You exceeded the number of attempts. Kindly start again'
        },
        versioning: {
            title: 'Version change log',
            version: 'Version',
            description: 'Changes description',
        },
        idle: {
            title: 'Session Expired',
            info1: 'Session has expired due to long period of inactivity.',
            info2: 'Application will reload after {value} seconds',
            reload: 'Reload now'
        }
    }
}
