import Vue from 'vue';
import VueI18n from 'vue-i18n';
import translationsEn from '@/i18n/translations-en';
import translationsEs from '@/i18n/translations-es';
import translationsPt from '@/i18n/translations-pt';
import translationsFr from '@/i18n/translations-fr';
import translationsCn from '@/i18n/translations-cn';
import translationsRo from '@/i18n/translations-ro';
import translationsUa from '@/i18n/translations-ua';
import translationsDk from '@/i18n/translations-dk';
import translationsMy from '@/i18n/translations-my';
import translationsPl from '@/i18n/translations-pl';
import translationsTr from '@/i18n/translations-tr';
import translationsHu from '@/i18n/translations-hu';
import translationsRu from '@/i18n/translations-ru';
import translationsDe from '@/i18n/translations-de';
import translationsIt from '@/i18n/translations-it';
import translationsFi from '@/i18n/translations-fi';


VueI18n.prototype.localeConfigs = [
    {
        code: 'en',
        label: 'English (English)'
    },
    {
        code: 'es',
        label: 'Spanish (Español)'
    },
    {
        code: 'pt',
        label: 'Portuguese (Português)'
    },
    {
        code: 'fr',
        label: 'French (Français)'
    },
    {
        code: 'cn',
        label: 'Chinese (中文)'
    },
    {
        code: 'ro',
        label: 'Romanian (Romana)'
    },
    {
        code: 'ua',
        label: 'Ukrainian (Українська)'
    },
    {
        code: 'dk',
        label: 'Dutch (Nederlands)'
    },
    {
        code: 'my',
        label: 'Malay (Melayu)'
    },
    {
        code: 'pl',
        label: 'Polish (Polski)'
    },
    {
        code: 'tr',
        label: 'Turkish (Türkçe)'
    },
    {
        code: 'hu',
        label: 'Hungarian (Magyar)'
    },
    {
        code: 'ru',
        label: 'Russian (Русский)'
    },
    {
        code: 'de',
        label: 'German (Deutsch)'
    },
    {
        code: 'it',
        label: 'Italian (Italiano)'
    },
    {
        code: 'fi',
        label: 'Finnish (Suomi)'
    }
];

Vue.use(VueI18n);

export default new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en: translationsEn.translations,
        es: translationsEs.translations,
        pt: translationsPt.translations,
        fr: translationsFr.translations,
        cn: translationsCn.translations,
        ro: translationsRo.translations,
        ua: translationsUa.translations,
        dk: translationsDk.translations,
        my: translationsMy.translations,
        pl: translationsPl.translations,
        tr: translationsTr.translations,
        hu: translationsHu.translations,
        ru: translationsRu.translations,
        de: translationsDe.translations,
        it: translationsIt.translations,
        fi: translationsFi.translations
    }
})
