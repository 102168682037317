import versions from '../../../all-employees-access-web/src/main/resources/app.version.json';

export default {

    getAppVersion(){
        if(!versions || !versions.length){
            return null;
        }

        return versions[0].appVersion;
    }
}