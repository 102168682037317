<template>
    <v-row>

        <v-col cols="12" class="title text-center primary--text mb-10">
            {{ $t('recoveryCredentials.validView.titleLbl') }}
        </v-col>

        <v-col cols="6" class="text-center">
            <v-btn @click="onLogin" outlined block class="py-10">
                <v-icon color="primary" size="45">mdi-account-outline</v-icon>
                <span class="ml-3 primary--text">{{ $t('recoveryCredentials.validView.loginBtn') }}</span>
            </v-btn>
        </v-col>

        <v-col cols="6" class="text-center">
            <v-btn @click="onPassword" outlined block class="py-10">
                <v-icon color="primary" size="45">mdi-account-lock-outline</v-icon>
                <span class="ml-3 primary--text">{{ $t('recoveryCredentials.validView.passwordBtn') }}</span>
            </v-btn>
        </v-col>

        <v-col cols="12" class="text-center">
            <div class="mt-10 text-center">
                <v-btn @click="$router.push('/')" outlined class="py-5" :data-cy="'navigation-menu-item-recover-credentials'">
                    <v-icon color="primary" size="25">mdi-arrow-left-bold-outline</v-icon>
                    <span class="ml-3 primary--text">{{ $t('common.returnBtn') }}</span>
                </v-btn>
            </div>
        </v-col>

    </v-row>
</template>

<script>
    export default {
        name: "LoginPasswordOption",
        methods: {
            onLogin() {
                this.$emit('change', {key: 'nextLogin'});
            },
            onPassword() {
                this.$emit('change', {key: 'nextPassword'});
            },
        },
        mounted() {
            this.$emit('mounted', this);
        }
    }
</script>



