<template>
    <v-dialog v-model="control.visible" persistent max-width="400">
        <v-card>

            <v-card-title class="primary--text">
                {{ $t('idle.title') }}
            </v-card-title>

            <v-card-text class="font-weight-bold mt-4">
                <p>
                    {{ $t('idle.info1') }}
                    {{ $t('idle.info2', {value: control.time}) }}
                </p>
            </v-card-text>

            <v-card-actions class="pr-6 pb-4">
                <v-spacer></v-spacer>

                <v-btn @click="reload" color="primary">{{ $t('idle.reload') }}</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        data: () => ({
            control: {
                visible: false,
                time: 10
            },
            interval: null
        }),
        methods: {
            startCounter(){
                this.interval = setInterval(() => {
                    if(!this.control.time){
                        this.reload();
                    } else {
                        this.control.time--;
                    }
                }, 1000);
            },

            reload(){
                this.control.visible = false;
                this.control.time = 10;

                clearInterval(this.interval);
                this.$router.push('/');
            }
        },
        onIdle(){
            if(this.$route.path !== '/'){
                this.control.visible = true;
                this.startCounter();
            }
        }
    }
</script>